import React from 'react'
import api from './api'

import '../css/Region.css'
// import Bg from '../images/Bg.webp'
// import Resource from '../images/Resource.webp'
import region_notify from '../images/region-notify.png'
import region_vip from '../images/region-vip.png'
import region_server from '../images/region-server.png'
import region_Extranet from '../images/region-Extranet.png'
import region_mods from '../images/region-mods.png'
import region_tutorial from '../images/region-tutorial.png'
import region_Server1 from '../images/region-Server1.png'


import { NotificationOutlined } from '@ant-design/icons'
import { Typography, Tag, Avatar, List } from 'antd'
import { Link } from 'react-router-dom'

import { Col, Row, Statistic } from 'antd'
import dayjs from 'dayjs'

const { Text } = Typography

//  转换图片
const GetImg = (name) => {
    if (name === 'Bg') {
        return 'https://cdn.n2yun.com.cn/cdn/img/2024/Bg.webp/webp'
    }
    if (name === 'Resource') {
        return 'https://cdn.n2yun.com.cn/cdn/img/2024/Resource.webp/webp'
    }
    if (name === 'region_notify') {
        return region_notify
    }
    if (name === 'region_vip') {
        return region_vip
    }
    if (name === 'region_server') {
        return region_server
    }
    if (name === 'region_Extranet') {
        return region_Extranet
    }
    if (name === 'region_mods') {
        return region_mods
    }
    if (name === 'region_tutorial') {
        return region_tutorial
    }
    if (name === 'region_Server1') {
        return region_Server1
    }
}

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


function Get() {
    if (localStorage.getItem('n2yun_get_index_data')) {
        return JSON.parse(localStorage.getItem('n2yun_get_index_data'))
    }
    return {
        Datas: {},
        Data_nums: {},
        Index_Data: []
    }
}


//  版块布局
class Region extends React.Component {
    state = {
        Data: Get()
    }

    componentDidMount() {
        api.get('/api/v2/get_index_data')
            .then((res) => {
                this.setState({
                    Data: res.data.data.data.Data
                })
                localStorage.setItem('n2yun_get_index_data', JSON.stringify(res.data.data.data.Data))
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                {
                    this.state.Data.Index_Data.map((item, index) => (
                        <div className='region' key={index}>
                            <div className='region_nav' style={{
                                background: `url(${GetImg(item.Bg)}) 100% 60% / 100%`,
                                height: 100
                            }}><h2>{item.title}<b>区</b></h2></div>
                            <ul>
                                {item.list.map((item, index) => (
                                    <li className='li' key={index} style={{
                                        borderLeftColor: `rgb(${item.color})`
                                    }}>
                                        <img src={GetImg(item.img)} className='img' alt='bimg'
                                            style={{
                                                width: this.props.state.isMobile ? '40px' : '64px',
                                                height: this.props.state.isMobile ? '40px' : '64px',
                                                left: this.props.state.isMobile ? '10px' : '20px',
                                            }}
                                        ></img>
                                        <Link to={`/grid/${item.category}`}>
                                            <div className='h2' style={{
                                                fontSize: this.props.state.isMobile ? '16px' : '24px',
                                                left: this.props.state.isMobile ? '60px' : '110px',
                                            }}>{item.title}</div>
                                        </Link>
                                        <p style={{
                                            top: this.props.state.isMobile ? '45px' : '45px',
                                            left: this.props.state.isMobile ? '20px' : '110px',
                                        }}>
                                            <Text type="secondary" style={{ fontSize: this.props.state.isMobile ? '10px' : '14px' }}>{item.describe}</Text>
                                        </p>
                                        <div className='tag'>
                                            <Tag color="#108ee9" icon={<NotificationOutlined />}>
                                                <Link to='/a'>通知</Link>
                                            </Tag>
                                        </div>
                                        <div className='list'>
                                            <List
                                                split={true}
                                                size='small'
                                                dataSource={this.state.Data.Datas[item.category]}
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        <Link to={`/u/${item.unid}`}><Avatar size='small' src={item.avatar} /></Link><Link to={`/t/${item.nid}`} style={{
                                                            fontSize: this.props.state.isMobile ? '10px' : '16px',
                                                        }}>{item.title} <Text style={{
                                                            fontSize: this.props.state.isMobile ? '8px' : '12px',
                                                        }}>{dayjs().from(dayjs(item.ctime))}</Text></Link>
                                                    </List.Item>
                                                )} />
                                        </div>
                                        <div className='topic'>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Statistic title="话题" value={this.state.Data.Data_nums[item.category]} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default Region;