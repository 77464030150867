import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Tag, Typography, message, Modal, Input, Table, Tooltip, Space, Alert, Image } from 'antd'


import { ProCard } from '@ant-design/pro-components'

const { Paragraph, Text } = Typography

const columns = [
    {
        title: '功能',
        dataIndex: 'key',
        rowScope: 'row',
    },
    {
        title: '普通会员',
        dataIndex: 'user',
    },
    {
        title: 'VIP会员',
        dataIndex: 'nv1',
    },
    {
        title: '定制服务',
        dataIndex: 'custom',
    },
]

const datas = [
    {
        key: 'NZ-admin [未更新]',
        user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    },
    // {
    //     key: '代码托管',
    //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
    //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    // },
    // {
    //     key: '去墙服务',
    //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
    //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    // },
    {
        key: 'NZ云存储',
        user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    },
    // {
    //     key: 'KOOK Bot托管',
    //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
    //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    // },
    {
        key: '更多定制功能',
        user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        nv1: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
    },
    {
        key: '费用',
        user: <Tag color="processing">免费</Tag>,
        nv1: <Tooltip title="QQ: 198513272">
            <Tag color="processing">19元/30天</Tag>
        </Tooltip>,
        custom: <Tooltip title="QQ: 198513272">
            <Tag color="warning">咨询站务</Tag>
        </Tooltip>,
    },
]


//  控制台-会员中心
function Member(props) {
    const [data, setData] = useState({ gip: [], token: '', member: '普通' })
    const [update, setUpdate] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [details, setDetails] = useState({ modal: null, total_fee: null, qr: null })


    useEffect(() => {
        let name = 'userMemberData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/user/get', {
            params: {
                name: 'member'
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const resetToken = useCallback(() => {
        api.post('/api/v2/user/update', {
            type: 'gtoken',
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已重置Token,请更新使用旧的Token的插件')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])


    //  取消绑定
    function unbind() {
        api.post('/api/v2/user/update', {
            type: 'unbind',
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已取消绑定,可以绑定其他的设备了')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = useCallback(() => {
        let Data = []

        for (let i = 0; i < data.gip.length; i++) {
            if (data.gip[i].length >= 1) {
                if (!validateIP(data.gip[i])) {
                    if (!validateDomain(data.gip[i])) {
                        message.warning('请检查是否有正确输入IP/域名')
                        return
                    }
                }
                Data.push(data.gip[i])
            }
        }
        setIsModalOpen(false)
        api.post('/api/v2/user/update', {
            type: 'gip',
            value: Data
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [data])

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onChange = (e) => {
        let name = parseInt(e.target.name)
        let value = e.target.value

        setData((data) => ({
            ...data,
            gip: [
                ...data.gip.slice(0, name),
                value,
                ...data.gip.slice(name + 1)
            ]
        }))
    }

    /*ip地址 0.0.0.0~255.255.255.255*/
    function validateIP(str) {
        var re =
            /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
        return re.test(str)
    }

    // 域名
    function validateDomain(str) {
        var re =
            /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/
        return re.test(str)
    }

    function onClick(name) {
        if (name === 'renew_membership') {
            setDetails({ ...details, modal: 'renew_membership' })
        }
        if (name === 'buy_membership') {
            setDetails({ ...details, modal: 'buy_membership' })
        }


        if (name === 'renew_membership_yes') {
            api.post('/api/v2/user/pay', {
                type: 'initiate_payment',
                renewal_vip: false,
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('已获取支付二维码')
                        setDetails({ ...details, modal: 'pay', total_fee: res.data.data.data.total_fee, qr: res.data.data.data.QRcode_url, out_trade_no: res.data.data.data.out_trade_no })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }
        if (name === 'buy_membership_yes') {
            api.post('/api/v2/user/pay', {
                type: 'initiate_payment',
                renewal_vip: true,
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('已获取支付二维码')
                        setDetails({ ...details, modal: 'pay', total_fee: res.data.data.data.total_fee, qr: res.data.data.data.QRcode_url, out_trade_no: res.data.data.data.out_trade_no })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }

        if (name === 'pay_yes') {
            api.post('/api/v2/user/pay', {
                type: 'pay_yes',
                out_trade_no: details.out_trade_no,
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('支付成功, 请刷新页面查看')
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }
    }



    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <Alert message="目前自助购买仅支持微信支付, 如需要使用支付宝支付, 请联系QQ: 198513272 进行人工处理" type="info" />
            </div>
            <ProCard title="会员信息" style={{ maxWidth: 300, float: 'left', marginRight: 20 }} bordered headerBordered>
                等级: {data.member === 'nv1' ? <>VIP会员 {data.expired ? <Tag color="error">已过期</Tag> : null}</> : '普通'}
                <br />
                状态: <Tag color="success">正常</Tag>
                <br />
                过期时间: {data.member === 'nv1' ? data.etime : '永不过期'}
                <br />

                {data.member === 'nv1' ?
                    <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => {
                        onClick('renew_membership')
                    }}>续费会员</Button>
                    :
                    <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => {
                        onClick('buy_membership')
                    }}>购买会员</Button>
                }
            </ProCard>
            <ProCard title="访问控制" style={{ maxWidth: (Math.floor(props.state.width * 0.6) - 200) - 380, float: 'left' }} bordered headerBordered>
                <Paragraph
                    copyable={{
                        text: `${data.token}`,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    Token: {data.token}
                </Paragraph>
                IP: {
                    data.gip.map((item, index) => (
                        <Tag color="default" key={index}>{item}</Tag>
                    ))
                }
                <br />
                允许绑定IP数量: <Tag color="processing">2</Tag>
                <br />
                <Button size='small' style={{ float: 'right' }} onClick={showModal}>编辑IP</Button>
                <Button type="primary" size='small' style={{ float: 'right', marginRight: 10 }} onClick={resetToken}>重置Token</Button>
            </ProCard>

            <ProCard title="NZ-admin 绑定信息" style={{ maxWidth: '100%', float: 'left', marginTop: 20 }} bordered headerBordered>
                绑定状态: {data.server ? <Tag color="success">已绑定</Tag> : <Tag color="error">未绑定</Tag>}
                <br />
                <Button type="primary" size='small' style={{ float: 'right' }} onClick={unbind}>取消绑定</Button>
            </ProCard>

            <Modal title="编辑IP" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input placeholder="IP-1" style={{ marginTop: 10 }} name='0' onChange={onChange} value={data.gip[0]} />
                <Input placeholder="IP-2" style={{ marginTop: 10 }} name='1' onChange={onChange} value={data.gip[1]} />
            </Modal>
            <props.clear />
            <Table
                columns={columns}
                dataSource={datas}
                pagination={false}
                style={{
                    marginTop: 20
                }}
                bordered />

            <Modal
                title="续费会员操作"
                centered
                open={details.modal === 'renew_membership'}
                onOk={() => { onClick('renew_membership_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>级别: VIP会员</Text>
                    <Text>价格: 19元/30天</Text>
                    <Text>备注: 目前可用的功能请参考下方的介绍</Text>
                </Space>
            </Modal>

            <Modal
                title="购买会员操作"
                centered
                open={details.modal === 'buy_membership'}
                onOk={() => { onClick('buy_membership_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>级别: VIP会员</Text>
                    <Text>价格: 19元/30天</Text>
                    <Text>备注: 目前可用的功能请参考下方的介绍</Text>
                </Space>
            </Modal>

            <Modal
                title="微信扫码支付"
                centered
                open={details.modal === 'pay'}
                onOk={() => { onClick('pay_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>价格: {details.total_fee}元</Text>
                    <Text>备注: 支付完成后请稍等几秒, 系统自动处理订单</Text>
                    <Image
                        width={200}
                        src={details.qr}
                    />
                </Space>
            </Modal>
        </div>
    )
}

export default Member;