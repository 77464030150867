import React, { useState, useLayoutEffect } from 'react'
import api from '../api'
import './Home.css'

import { Tag, Typography, Statistic, Table, message } from 'antd'

import CountUp from 'react-countup'

import { ProCard } from '@ant-design/pro-components'
import RcResizeObserver from 'rc-resize-observer'

const { Divider } = ProCard
const { Paragraph } = Typography

const formatter = (value) => <CountUp end={value} separator="," />

let url = 'http://localhost:3000'

if (process.env.NODE_ENV === 'production') {
    url = 'https://www.n2yun.com.cn'
}

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '类型',
        dataIndex: 'category',
        key: 'category',
        render: ((category) => {
            return (
                <Tag color="processing">{category}</Tag>
            )
        }),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Tag color="processing" className='clogs-check'>
                <a href={url + `/edit/${record.nid}`}>编辑</a>
            </Tag>
        ),
    },
];

//  控制台-总览
function Home(props) {
    const [responsive, setResponsive] = useState(false)
    const [data, setData] = useState({ watch: 0, like: 0, article: [], focus: 0, fan: 0 })

    useLayoutEffect(() => {
        let name = 'userData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/get_data_overview')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    localStorage.removeItem(name)
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {

            }
        }
    }

    return (
        <div>
            <RcResizeObserver
                key="resize-aobserver"
                onResize={(offset) => {
                    setResponsive(offset.width < 596);
                }}
            >
                <ProCard.Group title="数据总览" tooltip="这是展示你当前账户各类型的数据" bordered direction={responsive ? 'column' : 'row'}>
                    <ProCard  >
                        <Statistic title="文章" value={data.article.length} formatter={formatter} />
                    </ProCard>
                    <Divider type={responsive ? 'horizontal' : 'vertical'} />
                    <ProCard>
                        <Statistic title="阅读" value={data.watch} formatter={formatter} />
                    </ProCard>
                    <Divider type={responsive ? 'horizontal' : 'vertical'} />
                    <ProCard>
                        <Statistic title="点赞" value={data.like} formatter={formatter} />
                    </ProCard>
                    <Divider type={responsive ? 'horizontal' : 'vertical'} />
                    <ProCard>
                        <Statistic title="已关注" value={data.fan} formatter={formatter} />
                    </ProCard>
                    <Divider type={responsive ? 'horizontal' : 'vertical'} />
                    <ProCard>
                        <Statistic title="粉丝" value={data.focus} formatter={formatter} />
                    </ProCard>
                </ProCard.Group>
            </RcResizeObserver>

            <Table
                columns={columns}
                dataSource={data.article}
                pagination={false}
                style={{
                    marginTop: 20
                }}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }}
            />
        </div>
    )
}

export default Home;