import React, { useEffect } from 'react'
import api from './api'
import '../css/Login.css'


import { useNavigate, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { message, Result } from 'antd'


//  邮件验证布局
function Verify() {
    const id = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        let token = id.id
        const key = Date
        message.loading({ key, content: '正在验证中...', })
        api.post('/api/v2/verify', {
            verifyToken: token,
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success({ key, content: '验证成功,正在跳转至登录页面' })
                    navigate('/login')
                } else {
                    message.error({ key, content: res.data.data.text })
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    return (
        <div>
            <Result
                icon={<LoadingOutlined />}
                title="正在进行验证..."
            />
        </div>
    )
}

export default Verify;