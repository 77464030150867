import { useState, useEffect } from 'react'
import '../css/Nav.css'

import Logo from '../images/logo.png'

import { UserOutlined, NotificationOutlined, ExportOutlined, PieChartOutlined, ControlOutlined } from '@ant-design/icons'
import { Button, Badge, message, Avatar, Dropdown, Input, Tag, Typography } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const { Search } = Input


//  检查登陆状态
function CheckLoginStatus(props) {
    const location = useLocation()
    const navigate = useNavigate()
    let items = [
        {
            key: 'user',
            label: '个人资料',
            icon: <UserOutlined />,
            style: {
                width: 160
            }
        },
        {
            key: 'console',
            label: '控制台',
            icon: <PieChartOutlined />,
            style: {
                width: 160
            }
        }
    ]

    if (props.verifyAy('admin')) {
        items.push({
            key: 'admin',
            label: '站务管理',
            icon: <ControlOutlined />,
            style: {
                width: 160
            }
        })
    }

    items.push({
        type: 'divider'
    })
    items.push({
        key: 'quit',
        label: '退出登陆',
        icon: <ExportOutlined />,
        danger: true
    })

    //  处理下文列表菜单
    function onClick({ key }) {
        if (key === 'quit') {
            message.error('已退出登陆')
            props.clearCookie('n2yunToken')
            props.handleChange({
                token: null,
                user: {},
                login: false
            })
            localStorage.removeItem('n2yun_token')
            localStorage.removeItem('n2yun_user')
        }
        if (key === 'console') {
            navigate('/app/home')
        }
        if (key === 'cloud') {
            navigate('/cloud/cwhitelist')
        }
        if (key === 'user') {
            navigate('/app/set')
        }
        if (key === 'admin') {
            navigate('/admin/users')
        }
    }

    if (props.state.token) {
        return (
            <>
                <Badge dot={props.show} style={{ marginRight: 10 }} >
                    <NotificationOutlined onClick={props.ShowClick} style={{ fontSize: 20, marginRight: 10 }} />
                </Badge>
                <Dropdown
                    menu={{
                        items,
                        onClick
                    }}
                    placement="bottomRight"
                >
                    <Avatar size={34} src={<img src={props.function.getAvatar()} alt='avatar' />} />
                </Dropdown>
            </>
        )
    } else {
        return (
            <>
                <Link to={{ "pathname": "/login", search: "?redirect=" + location.pathname }} >
                    <Button icon={<UserOutlined />}>登录</Button>
                </Link>
            </>
        )
    }
}

//  导航栏
const Nav = (props) => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    let nav = 'nav'
    if (props.state.isMobile) {
        nav = 'nav-m'
    }

    useEffect(() => {
        if (props.state.user) {
            if (props.state.user.dot) {
                setShow(true)
            } else {
                setShow(false)
            }
        }
    }, [props.state.user])

    //  操作通知
    const ShowClick = () => {
        navigate('/notify')
    }

    const onSearch = (value) => {
        navigate('/search/' + value)
    }

    return (
        <div>
            {/* <div className='a666'><Tag color="#108ee9">活动推销</Tag> 网站会员20元起 <Typography.Link href="/t/340" >了解更多</Typography.Link></div> */}
            <div className='nav' >
                <div id={nav}>
                    <div className='logo'>
                        <Link to="/">
                            <img src={Logo} alt='logo'></img>
                        </Link>
                    </div>
                    {/* <div className='link' style={{ display: `${props.state.isMobile ? 'none' : ''}` }}>
                        <Button href='/nzadmin' style={{ marginRight: 10 }}>NZadmin</Button>
                        <Button href='https://docs.n2yun.com.cn/' target="_blank">N2YUN知识库</Button>
                    </div> */}
                    <div className='user_profile'>
                        <Search
                            placeholder="搜索..."
                            onSearch={onSearch}
                            enterButton
                            style={{
                                width: props.state.isMobile ? 120 : 200,
                                marginRight: 10
                            }}
                        />
                        <CheckLoginStatus {...props} show={show} ShowClick={ShowClick} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nav