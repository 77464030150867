import React, { useState, useLayoutEffect } from 'react'
import api from '../api'

import { Space, Table, message, Button } from 'antd'

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '作者',
        dataIndex: 'uname',
        key: 'uname',
    },
    {
        title: '资源名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '购买时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '更新时间',
        dataIndex: 'etime',
        key: 'etime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary" size='small' href={`${record.url}`}>下载</Button>
            </Space>
        ),
    },
];

//  控制台-资产授权
function Aan(props) {
    const [data, setData] = useState([])

    useLayoutEffect(() => {
        let name = 'userResourcesData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/get_resources')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false} />
        </div>
    )
}

export default Aan;