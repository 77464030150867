import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'
import s3 from '../s3'

import { InboxOutlined } from '@ant-design/icons'
import { Button, Divider, Tag, Image, Space, message, Avatar, Typography, Input, Drawer, Upload, Select } from 'antd'
import dayjs from 'dayjs'

import { ProCard } from '@ant-design/pro-components'
import md5 from 'js-md5'

const { Text, Title } = Typography
const { Dragger } = Upload

//  控制台-设置
function Set(props) {
    const [sign, setSign] = useState('')
    const [name, setName] = useState('')
    const [avatar, setAvatar] = useState('')
    const [bimg, setBimg] = useState('')
    const [core, setCore] = useState('')
    const [file, setFile] = useState('')
    const [revise, setRevise] = useState({})
    const [password, setPassword] = useState({})
    const [open, setOpen] = useState(false)


    useLayoutEffect(() => {
        setName(props.state.user.name)
        setSign(props.state.user.sign)
        setAvatar(props.state.user.avatar)
        setBimg(props.state.user.bimg)
        setRevise(props.state.user)
        setCore(props.state.user.core)
    }, [])


    const handleBlur = useCallback((e) => {
        let name_ = e.target.name
        let value = e.target.value
        if (revise[name_] === value) {
            return
        }
        setRevise({
            ...revise,
            [name_]: value
        })

        api.post('/api/v2/user/update', {
            type: name_,
            value: value
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [revise])


    const onChange = (e) => {
        let name_ = e.target.name
        let value = e.target.value

        if (name_ === 'name') {
            setName(value)
        }
        if (name_ === 'sign') {
            setSign(value)
        }
        if (name_ === 'avatar') {
            setAvatar(value)
        }
        if (name_ === 'bimg') {
            setBimg(value)
        }
    }

    const onPasswordChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        setPassword((data) => (
            {
                ...data,
                [name]: value,
            }
        ))
    }

    const yesClick = useCallback(() => {
        if (!password.oid) {
            message.warning('请输入旧密码')
            return
        }
        if (!password.new) {
            message.warning('请输入新密码')
            return
        }
        if (!password.yesNew) {
            message.warning('请输入确认密码')
            return
        }
        if (password.yesNew !== password.new) {
            message.warning('新密码和确认密码不一样,请重新输入')
            return
        }

        api.post('/api/v2/user/update', {
            type: 'password',
            password_a: md5(password.oid),
            password_b: md5(password.yesNew),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('修改成功')
                    setPassword({})
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [password])


    //  文件上传
    const uploadProps = {
        name: file,
        maxCount: 1,
        customRequest(info) {
            if (info.file.size > 12457280) {
                message.error('文件大小超过10MB,无法上传')
                info.onError()
                return
            }
            s3.upload({
                type: file,
                file: info.file,
                name: info.file.name,
                size: info.file.size,
            }, (msg) => {
                if (file === 'avatar') {
                    setAvatar(msg.url)
                    updateContent('avatar', msg.url)
                }
                if (file === 'bimg') {
                    setBimg(msg.url)
                    updateContent('bimg', msg.url)
                }
                info.onSuccess()
                setOpen(false)
                message.success('上传成功')
            })
        },
    }

    function onAvatarClick() {
        setFile('avatar')
        setOpen(true)
    }

    function onBimgClick() {
        setFile('bimg')
        setOpen(true)
    }

    function updateContent(name, value) {
        setRevise({
            ...revise,
            [name]: value
        })

        api.post('/api/v2/user/update', {
            type: name,
            value: value
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    const onClose = () => {
        setOpen(false)
    }

    function onCoreChange(value) {
        setCore(value)
        api.post('/api/v2/user/update', {
            type: 'core',
            value: value
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    return (
        <div>
            <ProCard style={{ width: '100%' }} bordered>
                <Image
                    width='100%'
                    height={300}
                    src={bimg}
                />
                <div style={{ float: 'left', marginTop: 20 }}>
                    <Avatar size={64} src={<img src={avatar ? avatar : 'https://ui-avatars.com/api/?background=random&name=' + name} alt="avatar" />} />
                </div>
                <div style={{ marginTop: 20 }}>
                    <Text style={{ marginLeft: 10, fontSize: 20 }}>{props.state.user.name}</Text>
                    <Tag color="processing" style={{ marginLeft: 10 }}>NID: {props.state.user.nid}</Tag>
                    <div style={{ marginTop: 10 }}>
                        <Text style={{ marginLeft: 10 }} type="secondary">注册日期: {dayjs(props.state.user.ctime).format('YYYY-MM-DD')}</Text>
                        <Text style={{ marginLeft: 10 }} type="secondary">邮箱: {props.state.user.mail}</Text>
                    </div>
                </div>
                <Divider />
                <div>
                    <div style={{ float: 'left', width: '50%' }}>
                        <Text>用户名</Text>
                        <br />
                        <Input style={{ marginTop: 5, width: '90%' }} name='name' onBlur={handleBlur} onChange={onChange} value={name} />
                    </div>
                    <div style={{ float: 'left', width: '50%' }}>
                        <Text>签名</Text>
                        <br />
                        <Input style={{ marginTop: 5, width: '90%' }} name='sign' onBlur={handleBlur} onChange={onChange} value={sign} />
                    </div>
                    <props.clear />
                </div>

                <div style={{ marginTop: 20 }}>
                    <div style={{ float: 'left', width: '50%' }}>
                        <Text>头像链接</Text>
                        <br />
                        <Space.Compact
                            style={{ marginTop: 5, width: '90%' }}
                        >
                            <Input name='avatar' onBlur={handleBlur} onChange={onChange} value={avatar} />
                            <Button type="primary" onClick={onAvatarClick}>本地上传</Button>
                        </Space.Compact>
                    </div>
                    <div style={{ float: 'left', width: '50%' }}>
                        <Text>背景图链接</Text>
                        <br />
                        <Space.Compact
                            style={{ marginTop: 5, width: '90%' }}
                        >
                            <Input style={{ width: '90%' }} name='bimg' onBlur={handleBlur} onChange={onChange} value={bimg} />
                            <Button data-name='bimg' type="primary" onClick={onBimgClick}>本地上传</Button>
                        </Space.Compact>
                    </div>
                </div>
            </ProCard>

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 配置信息</Title>
                <p>选择你当前使用的框架以及版本,方便网站自动匹配你可适用的版本插件/脚本</p>
                <Select
                    style={{
                        width: 200,
                    }}
                    placeholder="选择当前插件适配的版本"
                    onChange={onCoreChange}
                    options={props.state.config.core}
                    value={core}
                />
            </ProCard>

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 修改密码</Title>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Text>旧密码</Text>
                    <br />
                    <Input name='oid' value={password.oid} style={{ marginTop: 5, width: '90%' }} onChange={onPasswordChange} />
                </div>
                <div style={{ float: 'left', width: '50%', marginTop: 20 }}>
                    <Text>新密码</Text>
                    <br />
                    <Input name='new' value={password.new} style={{ marginTop: 5, width: '90%' }} onChange={onPasswordChange} />
                </div>
                <div style={{ float: 'left', width: '50%', marginTop: 20, marginBottom: 20 }} >
                    <Text>确认新密码</Text>
                    <br />
                    <Input name='yesNew' value={password.yesNew} style={{ marginTop: 5, width: '90%' }} onChange={onPasswordChange} />
                </div>
                <Button type="primary" size='small' onClick={yesClick}>确认修改</Button>
            </ProCard>

            <Drawer
                placement='top'
                onClose={onClose}
                open={open}
                closable={false}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                    <p className="ant-upload-hint">
                        仅支持大小为10MB以内的单个文件上传,请遵守中国法律法规,禁止上传色情等违法图片。
                    </p>
                </Dragger>
            </Drawer>
        </div>
    )
}

export default Set;