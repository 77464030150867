import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { SyncOutlined } from '@ant-design/icons'
import { Typography, message, Input, Button, Space, DatePicker } from 'antd'

import { ProCard } from '@ant-design/pro-components'
const { Text, Title } = Typography
const { TextArea } = Input

//  控制台-网站设置
function Webset(props) {
    const [data, setData] = useState({ node: [] })
    const [dataVip, setVipData] = useState({})
    const [dataRes, setResData] = useState({})
    const [update, setUpdate] = useState(null)

    useLayoutEffect(() => {
        let name = 'userNodeMsgData'
        if (localStorage.getItem(name)) {
            setData({
                ...data,
                ...JSON.parse(localStorage.getItem(name))
            })
        }
        api.get('/api/v2/admin/get')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData({
                        ...data,
                        ...Data
                    })
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    function onChange(e) {
        let name = e.target.name
        let value = e.target.value

        setData({
            ...data,
            [name]: value
        })
    }

    function onVipChange(e) {
        let name = e.target.name
        let value = e.target.value

        setVipData({
            ...dataVip,
            [name]: value
        })
    }

    function onResChange(e) {
        let name = e.target.name
        let value = e.target.value

        setResData({
            ...dataRes,
            [name]: value
        })
    }

    function onVipTimeChange(value) {
        setVipData({
            ...dataVip,
            time: new Date(value).getTime()
        })
    }

    function onChangeNotify(e) {
        setData({
            ...data,
            notify: e.target.value
        })
    }



    function onVipClick() {
        const Data = dataVip
        if (!Data.nid) {
            return message.warning('请输入NID')
        }
        if (!Data.time) {
            return message.warning('请选择时间')
        }

        api.post('/api/v2/admin/rechargeNv', {
            data: JSON.stringify({
                value: Data
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('会员充值成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function onClicks() {
        const Data = data
        if (!Data.nid) {
            return message.warning('请输入NID')
        }
        if (!Data.amount) {
            return message.warning('请输入金额')
        }

        api.post('/api/v2/admin/recharge', {
            data: JSON.stringify({
                value: Data
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('充值成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function onResClick() {
        const Data = dataRes
        if (!Data.nid) {
            return message.warning('请输入NID')
        }
        if (!Data.rnid) {
            return message.warning('请输入托管ID')
        }

        api.post('/api/v2/admin/rechargeRes', {
            data: JSON.stringify({
                value: Data
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('资产授权成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function updateArticleRedis() {
        api.post('/api/v2/admin/updateRedis', {
            data: JSON.stringify({
                value: 'article'
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('更新缓存成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function updateNotify() {
        if (!data.notify) {
            return message.warning('请输入内容')
        }
        api.post('/api/v2/admin/updatae', {
            data: JSON.stringify({
                name: 'notify',
                value: data.notify,
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已更新网站公告')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    return (
        <div>
            <ProCard style={{ width: '100%', marginBottom: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 基本操作</Title>
                <div style={{ marginTop: 20 }}>
                    <Button type="primary" onClick={updateArticleRedis}><SyncOutlined /> 更新文章缓存</Button>
                </div>
            </ProCard>

            <ProCard style={{ width: '100%', marginBottom: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 网站公告</Title>
                <div style={{ marginTop: 20 }}>
                    <TextArea rows={4} style={{ marginBottom: 20 }} value={data.notify} onChange={onChangeNotify} />
                    <Button type="primary" onClick={updateNotify}><SyncOutlined /> 更新网站公告</Button>
                </div>
            </ProCard>

            <ProCard style={{ width: '100%' }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 充值中心</Title>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Text>NID</Text>
                    <br />
                    <Input style={{ marginTop: 5, width: 150 }} placeholder="nid..." name='nid' onChange={onChange} />
                </div>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 250
                        }}
                    >
                        <Input type='number' placeholder="请输入金额" name='amount' onChange={onChange} />
                        <Button type="primary" onClick={onClicks}>立即充值</Button>
                    </Space.Compact>
                </div>
            </ProCard>

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 资产授权</Title>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Text>NID</Text>
                    <br />
                    <Input style={{ marginTop: 5, width: 150 }} placeholder="nid..." name='nid' onChange={onResChange} />
                </div>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 250
                        }}
                    >
                        <Input type='number' placeholder="请输入托管ID" name='rnid' onChange={onResChange} />
                        <Button type="primary" onClick={onResClick}>立即授权</Button>
                    </Space.Compact>
                </div>
            </ProCard>

            <ProCard style={{ width: '100%', marginTop: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 授权会员</Title>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Text>NID</Text>
                    <br />
                    <Input style={{ marginTop: 5, width: 150 }} placeholder="nid..." name='nid' onChange={onVipChange} />
                </div>
                <div style={{ width: '50%', marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 400
                        }}
                    >
                        <DatePicker name='time' onChange={onVipTimeChange} />
                        <Button type="primary" onClick={onVipClick}>立即授权</Button>
                    </Space.Compact>
                </div>
            </ProCard>
        </div >
    )
}

export default Webset;