import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from './api'
import '../css/Grid.css'

import { useParams, Link, useNavigate } from 'react-router-dom'

import { NotificationOutlined, HeartOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons'

import { Col, Row, Tag, Avatar, Alert, Spin, FloatButton, message, Pagination } from 'antd'

//  防止重发
let timeoutId = false


function Get(name, data, load) {
    if (localStorage.getItem(name) && !data) {
        if (JSON.parse(localStorage.getItem(name)).total) {
            if (load === 1) {
                return false
            }
            if (load === 2) {
                return 'none'
            }
            return JSON.parse(localStorage.getItem(name))
        }
    }
    if (load === 1) {
        return true
    }
    if (load === 2) {
        return 'block'
    }
    return { total: 0, Data: [] }
}

//  资源版块布局
function Grid(props) {
    const id = useParams()
    const [data, setData] = useState(Get('n2yun_grid_' + id.id + '_' + id.p, id.search))
    const [spinning, setSpinning] = useState(Get('n2yun_grid_' + id.id + '_' + id.p, id.search, 1))
    const [display, setDisplay] = useState(Get('n2yun_grid_' + id.id + '_' + id.p, id.search, 2))
    const navigate = useNavigate()
    const [page, setPage] = useState({
        current: 1,
        pageSize: 20,
    })

    useLayoutEffect(() => {
        console.log(123456);
        if (id.search) {
            api.get('/api/v2/search/', {
                params: {
                    q: id.search,
                    range: page.current,
                    max: page.pageSize,
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        setData(res.data.data.data.Data)
                        setSpinning(false)
                        setDisplay('none')
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        } else {
            // let name = 'n2yun_grid_' + id.id + '_' + id.p
            // if (localStorage.getItem(name)) {
            //     if (JSON.parse(localStorage.getItem(name)).total) {
            //         setData(JSON.parse(localStorage.getItem(name)))
            //         setSpinning(false)
            //         setDisplay('none')
            //     }
            // }
            if (id.p) {
                setPage({
                    ...page,
                    current: parseInt(id.p)
                })
            }
            api.get('/api/v2/grid/' + id.id, {
                params: {
                    range: id.p || page.current,
                    max: page.pageSize,
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        localStorage.setItem('n2yun_grid_' + id.id, JSON.stringify(res.data.data.data.Data))
                        setData(res.data.data.data.Data)
                        setSpinning(false)
                        setDisplay('none')
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }
    }, [id])


    //  异步请求拉取并更新数据
    const asyncUpdateData = useCallback((data) => {
        let Data = data ? data : page

        if (id.search) {
            api.get('/api/v2/search/', {
                params: {
                    q: id.search,
                    range: Data.current,
                    max: Data.pageSize,
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        setData(res.data.data.data.Data)
                        setSpinning(false)
                        setDisplay('none')
                        timeoutId = false
                    } else {
                        this.setState({
                            token: null,
                            user: {},
                        })
                        localStorage.removeItem('n2yun_token')
                        localStorage.removeItem('n2yun_user')
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        } else {
            api.get('/api/v2/grid/' + id.id, {
                params: {
                    range: Data.current,
                    max: Data.pageSize,
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        localStorage.setItem('n2yun_grid_' + id.id, JSON.stringify(res.data.data.data.Data))
                        setData(res.data.data.data.Data)
                        setSpinning(false)
                        setDisplay('none')
                        navigate(`/grid/${id.id}/${Data.current}`)
                        timeoutId = false
                    } else {
                        this.setState({
                            token: null,
                            user: {},
                        })
                        localStorage.removeItem('n2yun_token')
                        localStorage.removeItem('n2yun_user')
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }
    }, [page])


    function initialization(data) {
        if (data === 'free') {
            return (
                <div className='tag'><Tag color="#00AA00" style={{ fontSize: 16, padding: 2, paddingLeft: 10, paddingRight: 10 }}>免费</Tag></div>
            )
        }
        if (data === 'vip') {
            return (
                <div className='tag'><Tag color="#AA00AA" style={{ fontSize: 16, padding: 2, paddingLeft: 10, paddingRight: 10 }}>付费</Tag></div>
            )
        }
    }

    return (
        <div>
            <Alert message={props.state.config.notify} type="info" showIcon={true} icon={<NotificationOutlined />} style={{
                marginTop: props.state.isMobile ? -20 : 0,
                marginBottom: props.state.isMobile ? -20 : 0,
            }} />
            <Row gutter={[8, 8]} style={{ marginTop: 32 }}>
                <div className="spin" style={{ display: `${display}` }}>
                    <Spin size="large" spinning={spinning} />
                </div>
                {
                    data.Data.map((item, index) => (
                        <Col span={props.state.isMobile ? 24 : 8} key={index} >
                            <div className='grid' >
                                <div className='like'><HeartOutlined /> {item.like}</div>
                                <div className='browse'><EyeOutlined /> {item.watch}</div>
                                <div className='download'><DownloadOutlined /> {item.download}</div>
                                <div className='background_image' style={{ background: `url("${item.img}") 100% 60% / 100%` }}></div>
                                <div className={`title ${item.type_}`}><Link to={`/t/${item.nid}`} style={{ color: '#fff' }}>{item.title}</Link></div>
                                <div className='avatar'><Link to={`/u/${item.unid}`} ><Avatar size={47} src={`${item.avatar}`} /></Link></div>
                                {initialization(item.type)}
                            </div>
                        </Col>
                    ))
                }
            </Row>
            <div style={{ textAlign: 'center', paddingTop: 20 }}>
                <Pagination
                    total={data.total}
                    current={page.current}
                    pageSize={page.pageSize}
                    onShowSizeChange={(current, size) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: current,
                            pageSize: size,
                        })
                        timeoutId = true
                        asyncUpdateData({
                            current: current,
                            pageSize: size
                        })
                    }}
                    onChange={(page, pageSize) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: page,
                            pageSize: pageSize,
                        })
                        timeoutId = true
                        asyncUpdateData({
                            current: page,
                            pageSize: pageSize
                        })
                    }}
                    pageSizeOptions={[20, 50, 100]}
                />
            </div>
            <FloatButton.Group shape="circle">
                {props.state.token ? <FloatButton href='/edit' /> : null}
                <FloatButton.BackTop />
            </FloatButton.Group>
        </div>
    )
}

export default Grid