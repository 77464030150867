import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Input, Table, message, Button, Drawer, Tag, Typography, Descriptions, Select } from 'antd'

const { Paragraph } = Typography

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '识别域名',
        dataIndex: 'ago',
        key: 'ago',
    },
    {
        title: '应变化的域名',
        dataIndex: 'end',
        key: 'end',
    },
    {
        title: '匹配方式',
        dataIndex: 'value',
        key: 'value',
        render: (value) => (
            <Tag color="processing">{value === 1 ? '域名匹配' : value === 9 ? '禁用' : 'URL匹配'}</Tag>
        ),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Tag color="processing" className='clogs-check' data-id={record.nid}>编辑</Tag>
        ),
    },
];

//  控制台-去墙服务 [配置]
function AwsConfig(props) {
    const [data, setData] = useState([])
    const [AwsData, setAwsData] = useState({})
    const [AwsAddData, setAwsAddData] = useState({ value: 1 })
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [update, setUpdate] = useState(null)


    useEffect(() => {
        let name = 'userAwsData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/get_aws')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const showDrawerAdd = () => {
        setOpenAdd(true)
    }
    const onAddClose = () => {
        setOpenAdd(false)
    }


    const onChange = useCallback((e) => {
        setAwsData({
            ...AwsData,
            [e.target.name]: e.target.value,
        })
    }, [AwsData])


    const onAddChange = useCallback((e) => {
        setAwsAddData({
            ...AwsAddData,
            [e.target.name]: e.target.value,
        })
    }, [AwsAddData])

    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let id = parseInt(event.target.dataset.id)
                let Data = localStorage.getItem('userAwsData') ? JSON.parse(localStorage.getItem('userAwsData')) : []
                for (let i = 0; i < Data.length; i++) {
                    if (Data[i].nid === id) {
                        setAwsData(Data[i])
                    }
                }
                showDrawer()
                return
            }
        }
    }

    const saveAws = useCallback(() => {
        let Data = AwsData
        api.post('/api/v2/update_aws', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已保存')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [AwsData])

    const addAws = useCallback(() => {
        let Data = AwsAddData
        if (!Data.ago) {
            return message.error('请输入被识别域名')
        }
        if (!Data.end) {
            return message.error('请输入国内域名')
        }
        Data.add = true
        api.post('/api/v2/update_aws', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    setOpenAdd(false)
                    setAwsAddData({})
                    message.success('已录入')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [AwsAddData])


    function handleChange(value) {
        setAwsData({
            ...AwsData,
            value: value,
        })
    }

    function handleAddChange(value) {
        setAwsAddData({
            ...AwsAddData,
            value: value,
        })
    }


    return (
        <div>
            <Button onClick={showDrawerAdd} style={{ float: 'right', marginBottom: 20 }}>数据录入</Button>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }}
            />
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="去墙服务 [配置]-详情">
                    <Descriptions.Item label="配置ID" span={3}>
                        <Paragraph
                            copyable={{
                                text: `${AwsData.nid}`,
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            <Tag color="default">{AwsData.nid}</Tag>
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="被识别域名" span={3}>
                        <Input placeholder="域名..." value={AwsData.ago} name='ago' onChange={onChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="国内域名" span={3}>
                        <Input placeholder="域名..." value={AwsData.end} name='end' onChange={onChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="匹配方式" span={3}>
                        <Select
                            value={AwsData.value}
                            style={{
                                width: 120,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 1,
                                    label: '域名匹配',
                                },
                                {
                                    value: 2,
                                    label: 'URL匹配',
                                },
                                {
                                    value: 9,
                                    label: '禁用',
                                },
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="生成时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {AwsData.ctime}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={saveAws} >保存</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
            <Drawer width={640} placement="right" closable={false} onClose={onAddClose} open={openAdd}>
                <Descriptions title="去墙服务 [配置]-录入">
                    <Descriptions.Item label="被识别域名" span={3}>
                        <Input placeholder="域名..." value={AwsAddData.ago} name='ago' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="国内域名" span={3}>
                        <Input placeholder="域名..." value={AwsAddData.end} name='end' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="匹配方式" span={3}>
                        <Select
                            value={AwsAddData.value}
                            style={{
                                width: 120,
                            }}
                            onChange={handleAddChange}
                            options={[
                                {
                                    value: 1,
                                    label: '域名匹配',
                                },
                                {
                                    value: 2,
                                    label: 'URL匹配',
                                },
                                {
                                    value: 9,
                                    label: '禁用',
                                },
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={addAws} >新建</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </div>
    )
}

export default AwsConfig;