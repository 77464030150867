import React, { useState, useLayoutEffect } from 'react'
import api from '../api'

import { Button, Input, Space, Table, Alert, Typography, message, Statistic } from 'antd'

import { ProCard, CheckCard } from '@ant-design/pro-components'

// import Alipay from 'https://i.n2yun.com.cn/cdn/n2_web/20240904/static/media/alipay.fb7960d688e287342a6f.png'
// import Wxpay from '../../images/wxpay.png'

const { Title } = Typography



//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '交易类型',
        dataIndex: 'label',
        key: 'label',
    },
    {
        title: '消费前余额',
        dataIndex: 'ago',
        key: 'ago',
    },
    {
        title: '消费后余额',
        dataIndex: 'end',
        key: 'end',
    },
    {
        title: '金额',
        dataIndex: 'money',
        key: 'money',
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
];

//  控制台-余额中心
function Over(props) {
    const [data, setData] = useState({ money: 0, over: [] })
    const [value, setValue] = useState('alipay')

    useLayoutEffect(() => {
        let name = 'userOverData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/user/get', {
            params: {
                name: 'over'
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    function onChange(value) {
        setValue(value)
    }

    return (
        <div>
            <Alert
                message="暂停充值"
                description="已暂停使用网站的余额充值功能, 等待我们完成业务调整, 如需要购买/续费网站会员请转移至 会员中心 进行操作"
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
            />
            <div style={{ minWidth: 300, float: 'left', marginRight: 20 }}>
                <ProCard title="余额" style={{ maxWidth: 300, marginBottom: 20 }} bordered headerBordered>
                    <Statistic value={data.money} suffix="¥" />
                </ProCard>
                {/* <ProCard title="充值说明" style={{ maxWidth: 300 }} bordered>
                    充值流程: 选择支付方式,输入金额,扫码支付,实时到账。
                    <br />
                    如果在充值时遇到问题,请联系站务进行处理。
                    <br />
                    注意事项:
                    <br />
                    进行充值即表明你已知 <a href="https://docs.qq.com/doc/DUUJRdFB6akZmZ1BL" target="_blank" rel="noopener noreferrer">充值政策</a> 里的内容并遵循。
                </ProCard> */}
            </div>
            {/* <ProCard title="自助充值" style={{ maxWidth: 500, float: 'left' }} bordered headerBordered>
                <CheckCard.Group style={{ width: '100%' }} defaultValue={value} onChange={onChange}>
                    <CheckCard
                        title="支付宝"
                        size='small'
                        cover={
                            <img
                                alt="alipay"
                                src={'https://i.n2yun.com.cn/cdn/n2_web/20240904/static/media/alipay.fb7960d688e287342a6f.png'}
                                style={{
                                    width: 120,
                                    height: 50
                                }}
                            />
                        }
                        value="alipay"
                        disabled
                    />
                    <CheckCard
                        title="微信支付"
                        size='small'
                        cover={
                            <img
                                alt="wxpay"
                                src={Wxpay}
                                style={{
                                    width: 120,
                                    height: 50
                                }}
                            />
                        }
                        value="wxpay"
                        disabled
                    />
                </CheckCard.Group>
                <Space.Compact
                    style={{
                        width: 200,
                        marginTop: 20
                    }}
                >
                    <Input type='number' placeholder="请输入金额" disabled />
                    <Button type="primary" disabled>立即充值</Button>
                </Space.Compact>
            </ProCard> */}
            <props.clear />
            <Title level={4}>充值/消费记录</Title>
            <Table
                columns={columns}
                dataSource={data.over}
                pagination={false}
                style={{
                    marginTop: 20
                }}
            />
        </div>
    )
}

export default Over;