import React, { useEffect, useState, useCallback } from 'react'
import api from './api'
import s3 from './s3'

import { useParams, useNavigate } from 'react-router-dom'

import { InboxOutlined } from '@ant-design/icons'

import { Drawer, message, Typography, Input, Button, Upload, Popconfirm, Result } from 'antd'

import { ProCard } from '@ant-design/pro-components'

const { Text, Title, Link } = Typography
const { TextArea } = Input
const { Dragger } = Upload


//  依赖项-编辑器布局
function Rely_Edit(props) {
    const id = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({ version: '1.0.0' })
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(null)
    const [height, setHeight] = useState(1300)

    useEffect(() => {
        if (id.id) {
            const nid = id.id
            api.get('/api/v2/rely/', {
                params: {
                    nid: nid
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        const Data = res.data.data.data.Data
                        getHosting({ ...Data })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        } else {
            let relyData = localStorage.getItem('relyData')
            if (relyData) {
                getHosting(JSON.parse(relyData))
            } else {
                setLoading(false)
                setTimeout(() => {
                    let edit_content = document.getElementsByClassName('edit_content')[0]
                    setHeight(edit_content ? edit_content.offsetHeight : 1300)
                }, 200)
            }
        }
    }, [])

    useEffect(() => {
        if (!update) {
            return
        }
        setTimeout(() => {
            localStorage.setItem("relyData", JSON.stringify(data))
        }, 100)
        setTimeout(() => {
            let edit_content = document.getElementsByClassName('edit_content')[0]
            setHeight(edit_content ? edit_content.offsetHeight : 1300)
        }, 200)
    }, [update])


    function getHosting(relyData) {
        setData({
            ...data,
            ...relyData
        })
        setLoading(false)

        setTimeout(() => {
            let edit_content = document.getElementsByClassName('edit_content')[0]
            setHeight(edit_content ? edit_content.offsetHeight : 1300)
        }, 500)
    }


    //  文件上传
    const uploadProps = {
        name: 'rely',
        maxCount: 1,
        customRequest(info) {
            if (info.file.size > 52457280) {
                message.error('文件大小超过50MB,无法上传')
                info.onError()
                return
            }
            s3.upload({
                type: 'rely',
                file: info.file,
                name: info.file.name,
                size: info.file.size,
            }, (msg) => {

                setData({
                    ...data,
                    url: msg.url
                })
                setUpdate(Date.now())
                setOpen(false)
                info.onSuccess()
                message.success('上传成功')
            })
        },
    }

    //  内容修改
    const onChange = useCallback((e) => {
        let html = null
        if (e.target.name === 'markdown') {
            html = window.markdown.toHTML(e.target.value)
        }
        setUpdate(Date.now())
        if (html) {
            setData({
                ...data,
                [e.target.name]: e.target.value,
                html: html
            })
            return
        }
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }, [data])


    //  文件上传窗口
    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    //  重置内容
    const confirm = (e) => {
        message.success('已经重置内容了!')
        setUpdate(Date.now())
        setData({ version: '1.0.0' })
    }

    function Send() {
        let Data = {}
        if (!data.title) {
            message.warning('请填写标题再发布')
            return
        }
        if (!data.name) {
            message.warning('请填写资源名称再发布')
            return
        }
        if (!data.msg) {
            message.warning('内容似乎没更改,请更改内容再发布')
            return
        }

        Data.title = data.title
        Data.name = data.name
        Data.version = data.version
        Data.msg = data.msg
        Data.rely = data.rely
        Data.url = data.url
        Data.rely_type = true

        api.post('/api/v2/rely/', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    navigate('/')
                    message.success(res.data.data.text)
                    localStorage.removeItem('relyData')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function Save() {
        let Data = {}
        if (!data.title) {
            message.warning('请填写标题再发布文章')
            return
        }
        if (!data.name) {
            message.warning('请填写资源名称再发布')
            return
        }
        if (!data.msg) {
            message.warning('内容似乎没更改,请更改内容再发布')
            return
        }

        Data.nid = id.id
        Data.title = data.title
        Data.name = data.name
        Data.version = data.version
        Data.msg = data.msg
        Data.rely = data.rely
        Data.url = data.url
        Data.rely_type = false

        api.post('/api/v2/rely/', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setTimeout(() => {
                        navigate('/')
                        message.success(res.data.data.text)
                        localStorage.removeItem('relyData')
                    }, 500);
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    if (!props.verifyAy('admin')) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="你没有这权限访问"
            />
        )
    }


    return (
        <div style={{ height: height }}>
            <div style={{ width: (window.innerWidth * 0.8) }} className='edit_content'>
                <Title level={3}>依赖项编辑器</Title>
                <ProCard title="信息配置" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <Input placeholder="标题" addonBefore='标题' style={{ width: 400 }} name='title' value={data.title} onChange={onChange} />
                    <Input placeholder="Server.Cfg 的 启动名称" addonBefore='资源名称' style={{ width: 400, marginLeft: 20 }} name='name' value={data.name} onChange={onChange} />
                    <Input placeholder="版本号" addonBefore='版本号' style={{ width: 400, marginLeft: 20 }} name='version' value={data.version} onChange={onChange} />
                </ProCard>

                <ProCard title='文件配置' bordered headerBordered style={{ marginBottom: 20 }}>
                    <Input placeholder="文件链接" style={{ width: 400, marginRight: 20 }} name='url' value={data.url} onChange={onChange} />
                    <Button onClick={showDrawer}>上传文件</Button>
                </ProCard>

                <ProCard title="操作" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    {id.id ?
                        <>
                            <Button type="primary" onClick={Save}>更新</Button>
                        </>
                        :
                        <>
                            <Button type="primary" onClick={Send}>发布</Button>
                            <Popconfirm
                                title="二次确认"
                                description="这将会清理当前所有数据并重新写内容的,是否确认重置内容?"
                                onConfirm={confirm}
                                okText="是"
                                cancelText="否"
                            >
                                <Button style={{ marginLeft: 20 }}>重置</Button>
                            </Popconfirm>
                        </>
                    }

                </ProCard>

                <ProCard title="介绍内容" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <div style={{ height: 200 }}>
                        <TextArea style={{ height: 200 }} showCount rows={20} maxLength={500} value={data.msg} name='msg' onChange={onChange} />
                    </div>
                </ProCard>
            </div>

            <Drawer
                title="上传文件"
                width={500}
                onClose={onClose}
                open={open}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                    <p className="ant-upload-hint">
                        仅支持大小为10MB以内的单个文件上传,请遵守中国法律法规,禁止上传色情等违法图片。
                    </p>
                </Dragger>
            </Drawer>
        </div>
    )
}

export default Rely_Edit