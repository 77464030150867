import React, { useState, useLayoutEffect } from 'react'
import api from './api'
import '../css/Article.css'
import '../css/github-markdown.css'

import { useParams, Link } from 'react-router-dom'

import { formatDate, formatNumber } from 'xijs'

import { InfoCircleFilled, SafetyCertificateFilled, LikeTwoTone, EyeTwoTone, FireTwoTone, CopyTwoTone, LikeOutlined, UserOutlined, FundTwoTone, DownloadOutlined, ShoppingCartOutlined, CommentOutlined } from '@ant-design/icons'

import { Typography, Tag, Tooltip, FloatButton, Button, Avatar, Result, Divider, List, Form, Input, QRCode, Popconfirm, message, Modal, Collapse, Alert } from 'antd'

import dayjs from 'dayjs'

const { Title, Text, Paragraph } = Typography

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

//  文章布局
function Article(props) {
    const id = useParams()
    const [data, setData] = useState({ versions: [], rely: [] })
    const [code, setCode] = useState(200)
    const [Delete, setDelete] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [update, setUpdate] = useState(null)
    const [width, setWidth] = useState(400)
    const [loading, setLoading] = useState(false)


    useLayoutEffect(() => {
        if (localStorage.getItem('n2yun_article_' + id.id)) {
            setData(JSON.parse(localStorage.getItem('n2yun_article_' + id.id)))
        } else {
            setCode(-1)
        }

        api.get('/api/v2/article/' + id.id)
            .then((res) => {
                if (res.data.code === 200) {
                    localStorage.setItem('n2yun_article_' + id.id, JSON.stringify(res.data.data.data.Data))
                    setData(res.data.data.data.Data)
                } else {
                    localStorage.removeItem('n2yun_article_' + id.id)
                }
                setCode(res.data.code)
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update, id.id])

    useLayoutEffect(() => {
        setTimeout(() => {
            let edit_content = document.getElementsByClassName('article_content')[0]
            setWidth(edit_content ? (edit_content.offsetWidth - (26.560 * 2)) : 400)
        }, 200)
    }, [update])

    /**
     * 渲染标签和阅读以及时间
     */
    function initialization(data, app) {
        if (data.type === 'free') {
            return (
                <div>
                    <Tag color="#00AA00">
                        <a href="/type/free">免费</a>
                    </Tag>
                    <Tag>
                        <a href={`/grid/${data.category}`}>{data.category_label}</a>
                    </Tag>
                    {props.state.isMobile ? null : <Text type="secondary" style={{ margin: 15, marginLeft: 5 }}>{formatDate(data.ctime, 'YY年MM月DD日 hh:mm:ss')} · 阅读 {formatNumber(data.watch)}</Text>}
                </div>
            )
        }
        if (data.type === 'vip') {
            return (
                <div>
                    <Tag color="#AA00AA">
                        <a href="/type/free">付费</a>
                    </Tag>
                    <Tag>
                        <a href={`/grid/${data.category}`}>{data.category_label}</a>
                    </Tag>
                    {props.state.isMobile ? null : <Text type="secondary" style={{ margin: 15, marginLeft: 5 }}>{formatDate(data.ctime, 'YY年MM月DD日 hh:mm:ss')} · 阅读 {formatNumber(data.watch)}</Text>}
                </div>
            )
        }
    }

    /**
     * 渲染认证数据
     */
    function GetCern(data) {
        if (data.cern) {
            return (
                <Tooltip title={data.cern}><SafetyCertificateFilled /></Tooltip>
            )
        }
    }

    /**
     * 渲染评论主要数据 (全部评论数量)
     */
    function All_Comment(data) {
        if (data.Comment) {
            if (data.Comment.length >= 1) {
                return (
                    <Title level={4} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} >全部评论 {data.Comment.length}</Title>
                )
            }
        }
    }

    /**
     * 渲染评论内容
     */
    function All_Comments(data) {
        if (data.Comment) {
            return (
                data.Comment.map((item, index) => (
                    <li key={index}>
                        <Avatar size={42} src={`${item.avatar}`} style={{ float: 'left', marginRight: 20, marginTop: 20 }} />
                        <div style={{ width: props.state.isMobile ? '79%' : '90%', float: 'right', marginTop: 20 }}>
                            <Title level={5} style={{ float: 'left', marginTop: 0, fontWeight: 400 }} >{item.name} {GetCern(item)}</Title>
                            <Text type="secondary" style={{ float: 'right' }} >{item.ctime}</Text>
                        </div>
                        <div className='comment_content' style={{ width: props.state.isMobile ? '79%' : '90%', float: 'right', marginTop: 0 }}>
                            {
                                item.sreply
                                    ?
                                    <Text ><a href={`/u/${item.sreply.nid}`}>@{item.sreply.name}</a> {item.text}</Text>
                                    :
                                    <Text >{item.text}</Text>
                            }
                        </div>
                        <div className='action-box' style={{ width: props.state.isMobile ? '80%' : '90%', float: 'left', marginTop: 10, marginLeft: 65 }}>
                            <div>
                                <Text type="secondary" ><LikeOutlined /> 点赞</Text>
                                <Text type="secondary" className='clogs-check' data-id={`r_${item._id}`} style={{ marginLeft: 10 }} onClick={onClick} ><CommentOutlined /> 回复</Text>
                                <Form
                                    className={[`r_${item._id}`, 'sComment']}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinishs}
                                    style={{
                                        display: 'none'
                                    }}
                                >
                                    <Form.Item name={'comment_' + item._id} style={{ marginTop: 10, marginBottom: 10 }} >
                                        <Input.TextArea rows={3} style={{ resize: 'none' }} placeholder={`回复${item.name}...`} />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>发表评论</Button>
                                    <Button style={{ float: 'right', marginRight: 10 }} onClick={onCloseClick}>取消</Button>
                                </Form>
                            </div>
                        </div>
                    </li>
                ))
            )
        }
    }

    /**
     * 渲染评论主体功能
     */
    function GetComment(data) {
        return (
            <div className='comment_lists'>
                {All_Comment(data)}
                <ul>
                    {All_Comments(data)}
                    <div style={{ clear: 'both' }} />
                </ul>
            </div>
        )
    }

    /**
     * 获取作者头像并渲染出来
     */
    function GetAvatar(data) {
        if (data.user.avatar) {
            return (
                <Avatar size={42} src={`${data.user.avatar}`} style={{ float: 'left', marginRight: 20, marginTop: 20 }} />
            )
        }
        return (
            <Avatar size={42} icon={<UserOutlined />} style={{ float: 'left', marginRight: 20, marginTop: 20 }} />
        )
    }

    //  关注作者
    function FocusOn() {
        api.get('/api/v2/add_focus', {
            params: {
                nid: data.unid,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已经关注此作者')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  取消关注作者
    function FocusOff() {
        api.get('/api/v2/cancel_focus', {
            params: {
                nid: data.unid,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已经取消关注此作者')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    /**
     * 判断是否显示关注作者按钮
     */
    function FocusBtn() {
        let user = props.state.user
        if (user) {
            if (data.focus) {
                for (let i = 0; i < data.focus.length; i++) {
                    if (data.focus[i].nid === user.nid) {
                        return <Button style={{ marginTop: 20 }} onClick={FocusOff} block>已关注</Button>
                    }
                }
            }
            return <Button type="primary" style={{ marginTop: 20 }} onClick={FocusOn} block>关注</Button>
        } else {
            return <Button type="primary" style={{ marginTop: 20 }} onClick={FocusOn} block>关注</Button>
        }
    }

    /**
     * 处理文章隐藏
     */
    const confirm = (e) => {
        let type = 'false'
        let name = 'article_hide'
        if (data.state === 2) {
            type = 'true'
            name = 'article_show'
        }
        handleArticleStatus({
            nid: data.nid,
            type: type,
            name: name,
        })
    }

    /**
     * 审核文章-通过
     */
    const reviewConfirm = (e) => {
        handleArticleStatus({
            nid: data.nid,
            type: 'true',
            name: 'approved',
        })
    }

    /**
       * 审核文章-拒绝
       */
    const rejectConfirm = (e) => {
        handleArticleStatus({
            nid: data.nid,
            type: 'false',
            name: 'reject',
        })
    }


    /**
     * 处理文章状态
     */
    function handleArticleStatus(data) {
        api.post('/api/v2/article/review', {
            ...data,
        })
            .then((res) => {
                if (res.data.code === 200) {
                    if (data.name === 'approved') {
                        message.success('已通过审核')
                    }
                    if (data.name === 'reject') {
                        message.success('已拒绝审核')
                    }
                    if (data.name === 'article_hide') {
                        message.success('已隐藏')
                    }
                    if (data.name === 'article_show') {
                        message.success('已恢复')
                    }
                    setUpdate(Date.now())
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    /**
     * * Created by 200
     * Date: 2023/03/30
     * 处理永久删除文章功能
     */
    const showDeleteModal = () => {
        setDelete(true)
    };
    const handleDeleteOk = () => {
        setConfirmLoading(true)
        api.post('/api/v2/article/delete', {
            token: props.getToken(),
            nid: data.nid,
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('已永久删除文章')
                    setTimeout(() => {
                        setUpdate(Date.now())
                    }, 500)
                } else {
                    message.error(res.data.data.text)
                }
                setConfirmLoading(false)
                setDelete(false)
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    };
    const handleDeleteCancel = () => {
        setDelete(false)
    };


    /**
     * 文章管理功能
     */
    function ArticleAdmin() {
        let verifyAy = props.verifyAy
        if (verifyAy('article_admin')) {
            let display = 'none'
            let show = ''
            let shows = ''
            let hide = ''
            if (verifyAy('article_deleted')) {
                display = ''
            }
            if (data.state === 0) {
                show = 'none'
                hide = 'none'
            }
            if (data.state === 1) {
                shows = 'none'
                show = 'none'
            }
            if (data.state === 2) {
                shows = 'none'
                hide = 'none'
            }
            return (
                <div className='card' style={{ float: 'left' }}>
                    <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} >文章管理</Title>
                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    <Link to={`/edit/${data.nid}`} style={{ color: '#fff' }}><Button style={{ marginBottom: 10, width: '100%' }}>编辑文章</Button></Link>
                    <Button style={{ marginBottom: 10, display: `${show}`, width: '100%' }} type="primary" onClick={confirm}>恢复</Button>
                    <Button style={{ marginBottom: 10, display: `${shows}`, width: '100%' }} type="primary" onClick={reviewConfirm}>通过</Button>
                    <Button style={{ marginBottom: 10, display: `${shows}`, width: '100%' }} type="primary" onClick={rejectConfirm} danger>拒绝</Button>
                    <Popconfirm
                        title="隐藏"
                        description="隐藏之后不会公开展示此文章,是否要隐藏?"
                        onConfirm={confirm}
                        okText="是"
                        cancelText="否"
                    >
                        <Button style={{ marginBottom: 10, display: `${hide}`, width: '100%' }} type="primary" danger>隐藏</Button>
                    </Popconfirm>

                    <Modal
                        title="永久删除"
                        open={Delete}
                        onOk={handleDeleteOk}
                        onCancel={handleDeleteCancel}
                        cancelText='取消'
                        okText='确认'
                        confirmLoading={confirmLoading}
                    >
                        <p>你正在进行 <b>永久删除</b> 敏感操作,请确认是否永久删除?</p>
                    </Modal>

                    <Button onClick={showDeleteModal} style={{ marginBottom: 10, display: `${display}`, width: '100%' }} danger>永久删除</Button>
                </div>
            )
        } else {
            if (props.state.user.nid === data.unid) {
                return (
                    <div className='card' style={{ float: 'left' }}>
                        <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} >文章管理</Title>
                        <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                        <Link to={`/edit/${data.nid}`} style={{ color: '#fff' }}><Button style={{ width: '100%' }}>编辑文章</Button></Link>
                    </div>
                )
            }
        }
    }

    /**
     * 下载功能处理
     */
    function download(url) {
        setLoading(true)
        api.get('/api/v2/article/download/' + data.nid + '?url=' + url)
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    const link = document.createElement('a')
                    link.href = Data
                    link.click()
                    setLoading(false)
                    message.success('已经开始下载了')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function downloadRely(url) {
        api.get('/api/v2/rely/download/' + url)
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    const link = document.createElement('a')
                    link.href = Data
                    link.click()
                    message.success('已经开始下载了')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  给作者点个赞
    function likeTheAuthor() {
        api.get('/api/v2/article/like/' + data.nid)
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('你已给这位作者点了赞')
                } else {
                    if (res.data.code === 400) {
                        return
                    } else {
                        message.error(res.data.data.text)
                    }
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  购买资源
    const buy = () => {
        api.get('/api/v2/article/buy/' + data.nid)
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('成功购买此资源')
                } else {
                    if (res.data.code === 400) {
                        return
                    } else {
                        message.error(res.data.data.text)
                    }
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    /**
     * 文章信息
     */
    function ArticleOperate(props) {
        if (data.price && data.type === 'vip') {
            return (
                <div className='card' style={{ float: 'left', display: props.state.isMobile ? 'none' : 'block' }}>
                    <Text type="secondary">价格: <Text>{data.price}</Text></Text>
                    <Divider />
                    {data.yes ?
                        data.versions.length >= 1 ? <div>
                            {data.versions.map((i, key) => {
                                return (
                                    <div key={key} style={{ marginBottom: 20 }}>
                                        <Text type="secondary">版本: <Text>{i.name}</Text></Text>
                                        {i.code ? <div style={{ marginBottom: 10 }}>
                                            <Paragraph
                                                copyable={{
                                                    text: `${i.code}`,
                                                }}
                                            >
                                                <Text type="secondary">提取码: <Text>{i.code}</Text></Text>
                                            </Paragraph>
                                        </div> : ''}
                                        <br />
                                        <Button type="primary" onClick={() => {
                                            download(i.url)
                                        }} icon={<DownloadOutlined />} block>下载资源</Button>
                                    </div>
                                )
                            })}
                        </div> : ''
                        :
                        <Popconfirm
                            title="购买"
                            description={`你将花费 ${data.price} 元来购买此资源`}
                            icon={
                                <InfoCircleFilled
                                    style={{
                                        color: '#1677FF',
                                    }}
                                />
                            }
                            onConfirm={buy}
                        >
                            <Button style={{ marginTop: 20 }} icon={<ShoppingCartOutlined />} block>购买资源</Button>
                        </Popconfirm>
                    }
                </div>
            )
        }

        return (
            <div className='card' style={{ float: 'left', display: props.state.isMobile ? 'none' : 'block' }}>
                {data.yes ?
                    data.versions.length >= 1 ? <div>
                        {data.versions.map((i, key) => {
                            return (
                                <div key={key} style={{ marginBottom: 20 }}>
                                    <Text type="secondary">版本: <Text>{i.name}</Text></Text>
                                    {i.code ? <div style={{ marginBottom: 10 }}>
                                        <Paragraph
                                            copyable={{
                                                text: `${i.code}`,
                                            }}
                                        >
                                            <Text type="secondary">提取码: <Text>{i.code}</Text></Text>
                                        </Paragraph>
                                    </div> : ''}
                                    <br />
                                    <Button type="primary" onClick={() => {
                                        download(i.url)
                                    }} icon={<DownloadOutlined />} block>下载资源</Button>
                                </div>
                            )
                        })}
                    </div> : ''
                    :
                    <Button onClick={likeTheAuthor} icon={<LikeOutlined />} style={{ marginBottom: 20 }} block>给作者点个赞</Button>
                }
            </div>
        )
    }


    //  发布评论
    function onFinish(values) {
        let comment = values.comment
        if (!comment) {
            return message.warning('请输入内容')
        }
        api.post('/api/v2/add_comment', {
            data: JSON.stringify({
                nid: data.nid,
                comment: comment,
            }),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('评论已发出')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  回复评论
    function onFinishs(object) {
        for (const key in object) {
            if (object[key]) {
                api.post('/api/v2/add_comment', {
                    data: JSON.stringify({
                        id: key.slice(8),
                        nid: data.nid,
                        comment: object[key],
                    }),
                })
                    .then((res) => {
                        if (res.data.code === 200) {
                            setUpdate(Date.now())
                            message.success('已回复')
                        } else {
                            message.error(res.data.data.text)
                        }
                    })
                    .catch((error) => {
                        message.error('网络异常,无法连接服务器')
                    })
                return
            }
        }
        return message.warning('请输入内容')
    }

    //  显示回复评论框
    function onClick(e) {
        let id = e.target.dataset.id
        const element = document.querySelector(`.${id}`)
        const elements = document.querySelectorAll('.sComment')
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
        element.style.display = 'block'
    }

    //  关闭回复评论框
    function onCloseClick(e) {
        const elements = document.querySelectorAll('.sComment')
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none'
        }
    }


    //  框架提示
    function Core() {
        if (data.core) {
            let yes = false
            let Cores = []
            let no = data.core.length >= 1 ? false : true

            for (let i = 0; i < data.core.length; i++) {
                const element = data.core[i]
                if (element === 'independent') {
                    yes = true
                }
                if (element === props.state.user.core) {
                    yes = true
                }
            }

            for (let i = 0; i < data.core.length; i++) {
                for (let j = 0; j < props.state.config.core.length; j++) {
                    if (data.core[i] === props.state.config.core[j].value) {
                        Cores.push(props.state.config.core[j].label)
                    }
                }
            }

            if (yes) {
                return (
                    <Alert message="本插件适合你当前服务器使用" type="success" />
                )
            } else {
                if (no) {
                    return (
                        <Alert message="本插件未标注适配版本,请自行确认是否适配" type="warning" />
                    )
                }
                return (
                    <Alert message="本插件所标注适配版本,没有适合你当前使用的版本" description={`只适配: ${Cores.map((i) => (i))}`} type="error" />
                )
            }
        }
    }

    /**
     * 获取文章数据并渲染
     */
    function GetData() {
        if (code === 200) {
            return (
                <div>
                    <div className='article'>
                        <div className='article_content'>
                            <div id='markdown-body'>
                                <Title level={2} style={{ marginTop: 0 }}>{data.title}</Title>
                                <div style={{ marginBottom: 20 }}>
                                    {initialization(data)}
                                </div>

                                {props.state.isMobile ? <div style={{ marginBottom: 20 }}>
                                    <div style={{ float: 'left' }}>
                                        <Link to={`/u/${data.unid}`} ><Avatar size={45} src={`${data.avatar}`} style={{ float: 'left' }} /></Link>
                                        <ul style={{ margin: 0, marginLeft: 10 }}>
                                            <li style={{ listStyle: 'none' }}>
                                                <Title level={4} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400, fontSize: 16 }} >{data.name} {GetCern(data)}</Title>
                                            </li>
                                            <li style={{ listStyle: 'none' }}>
                                                <Text style={{ fontSize: 12 }}><Text type="secondary" style={{ fontSize: 12 }}>{dayjs().from(dayjs(data.ctime))} · 阅读 {formatNumber(data.watch)}</Text></Text>
                                            </li>
                                        </ul>
                                    </div>
                                    <div style={{ float: 'right', width: 100, marginTop: -10 }}><FocusBtn /></div>
                                    <props.clear />
                                </div> : null}

                                <div style={{ marginBottom: 20 }}>
                                    <Alert message="资源下载方法" description="请在电脑上操作, 右边给作者点个赞之后就可以下载了!" />
                                </div>

                                <div style={{ marginBottom: 20 }}>
                                    <Core />
                                </div>

                                <div style={{ marginBottom: 20, display: data.rely.length >= 1 ? '' : 'none' }}>
                                    <Collapse
                                        collapsible="header"
                                        bordered={false}
                                        items={[
                                            {
                                                key: '1',
                                                label: <Tag color="processing">本插件所需要的依赖项 [前置插件]</Tag>,
                                                children: (
                                                    <div>
                                                        {data.rely.map((i, key) => {
                                                            return (
                                                                <div style={{ marginBottom: 20 }}>
                                                                    <Text>{i.title}</Text>
                                                                    <Tag color="processing" style={{ marginLeft: 10 }}>v{i.version}</Tag>
                                                                    <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => { downloadRely(i.nid) }}>点击下载</Button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />
                                </div>
                                <div className='markdown-body' dangerouslySetInnerHTML={{ __html: data.html }}></div>
                            </div>
                            <div style={{ clear: 'both' }} />
                            <div className='comment' style={{ width: width }}>
                                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} ><CopyTwoTone /> 评论</Title>
                                {GetAvatar(props.state)}
                                <Form
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item name={'comment'} style={{ marginTop: 20, marginBottom: 10 }} >
                                        <Input.TextArea rows={3} style={{ resize: 'none' }} placeholder='输入评论' />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}  >发表评论</Button>
                                </Form>
                                <div style={{ clear: 'both' }} />
                                {GetComment(data)}
                            </div>
                        </div>
                        <div className='card' style={{ float: 'left', display: props.state.isMobile ? 'none' : 'block' }}>
                            <Link to={`/u/${data.unid}`} ><Avatar size={50} src={`${data.avatar}`} style={{ float: 'left' }} /></Link>
                            <ul style={{ margin: 0, marginLeft: 20 }}>
                                <li >
                                    <Title level={4} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} >{data.name} {GetCern(data)}</Title>
                                </li>
                                <li style={{ listStyle: 'none' }}>
                                    <Text >{data.utitle}</Text>
                                </li>
                            </ul>
                            <FocusBtn />
                            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                            <div>
                                <LikeTwoTone style={{ marginRight: 4 }} /><Text> 获得点赞 {formatNumber(data.like)}</Text>
                            </div>
                            <div style={{ marginTop: 4 }}>
                                <EyeTwoTone style={{ marginRight: 4 }} /><Text> 文章被阅读 {formatNumber(data.watch)}</Text>
                            </div>
                            <div style={{ marginTop: 4 }}>
                                <FundTwoTone style={{ marginRight: 4 }} /><Text> 下载次数 {formatNumber(data.download)}</Text>
                            </div>
                        </div>
                        <ArticleOperate {...props} />
                        <ArticleAdmin />
                        <div className='card' style={{ float: 'left', display: 'none' }}>
                            <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} >手机扫码访问</Title>
                            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                            <QRCode value={`http://192.168.0.156:3000/t/${data.nid}`} style={{ margin: 'auto' }} />
                        </div>
                        <div className='card' style={{ float: 'left', display: props.state.isMobile ? 'none' : 'block' }}>
                            <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} ><FireTwoTone /> 热门文章</Title>
                            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                            <List
                                split={false}
                                dataSource={data.Data}
                                renderItem={(item) => (
                                    <List.Item style={{ padding: 5 }}>
                                        <a href={`/t/${item.nid}`}><Text>{item.title}</Text> </a>
                                    </List.Item>
                                )}
                            />
                        </div>
                        <div style={{ clear: 'both' }} />
                        <FloatButton.Group shape="circle">
                            <FloatButton.BackTop />
                        </FloatButton.Group>
                    </div>
                </div>
            )
        }
        if (code === 401) {
            return (
                <Result title="该文章处于审核/下架状态" />
            )
        }
        if (code === 402) {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="您访问的页面已经跑路了"
                />
            )
        }
    }

    return (
        <div>
            <GetData />
        </div>
    )
}

export default Article