import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Table, message, Button, Input, Tag, Typography, Drawer, Descriptions, Select } from 'antd'

const { Paragraph } = Typography
const { Search } = Input

//  表格
const columns = [
    {
        title: 'NID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '用户名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '会员级别',
        dataIndex: 'data',
        key: 'data',
        render: ((_, record) => {
            return (
                record.config.member
            )
        }),
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
    },
    {
        title: '余额',
        dataIndex: 'integral',
        key: 'integral',
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        dataIndex: 'data',
        key: 'data',
        render: ((data, record, index) => {
            let msg = ''
            for (const key in data) {
                msg = msg + '[' + key + ']: ' + data[key] + '\n'
            }
            return (
                <>
                    <Tag color="processing" className='clogs-check' data-_id={record._id}>查看</Tag>
                </>
            )
        }),
    },
];

let timeoutId = false

//  控制台-网站用户
function Users(props) {
    const [data, setData] = useState({ total: 0, Data: [], Types: [], userConfig: {} })
    const [logsData, setLogsData] = useState({ _id: '', name: '', gameId: '', type: '', data: {}, ctime: '' })
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fc, setFc] = useState(undefined)
    const [type, setType] = useState(undefined)
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
    })

    useLayoutEffect(() => {
        let name = 'userUsersData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/admin/users', {
            params: {
                range: page.current,
                max: page.pageSize,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let _id = event.target.dataset._id
                let Data = localStorage.getItem('userUsersData') ? JSON.parse(localStorage.getItem('userUsersData')).Data : []
                for (let i = 0; i < Data.length; i++) {
                    if (Data[i]._id === _id) {
                        setLogsData(Data[i])
                    }
                }
                showDrawer()
                return
            }
        }
    }

    //  异步请求拉取并更新数据
    const asyncUpdateData = useCallback((data, fcData, typeData, refresh) => {
        let name = 'userUsersData'
        let Data = data ? data : page
        let Fc = fcData ? fcData : fc
        let Type = typeData ? typeData : typeData === 0 ? typeData : type

        setLoading(true)
        api.get('/api/v2/admin/users', {
            params: {
                range: Data.current,
                max: Data.pageSize,
                fc: Fc,
                type: Type,
                refresh: refresh,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                    setLoading(false)
                    timeoutId = false
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [page, fc])

    const onSearch = ((value) => {
        setFc(value)
        asyncUpdateData({
            current: 1,
            pageSize: 10,
        }, value ? value : 'undefined')
    })

    const Refresh = () => {
        asyncUpdateData(null, null, null, true)
    }

    function handleChange(value) {
        setType(value)
        asyncUpdateData(null, null, value)
    }

    return (
        <div>
            <Search
                placeholder="搜索用户名、NID"
                onSearch={onSearch}
                style={{
                    width: 260,
                    float: 'right',
                    marginBottom: 20
                }}
            />
            <Select
                placeholder='选择状态'
                style={{
                    float: 'right',
                    width: 120,
                    marginBottom: 20,
                    marginRight: 10
                }}
                onChange={handleChange}
                options={data.Types}
            />
            <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
            <Table
                columns={columns}
                dataSource={data.Data}
                loading={loading}
                pagination={{
                    total: data.total,
                    current: page.current,
                    pageSize: page.pageSize,
                    showSizeChanger: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total) => {
                        return '总计 ' + total + ' 条数据'
                    },
                    onShowSizeChange: (current, size) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: current,
                            pageSize: size,
                        })
                        timeoutId = true
                        asyncUpdateData({ current: current, pageSize: size, })
                    },
                    onChange: (page, pageSize) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: page,
                            pageSize: pageSize,
                        })
                        timeoutId = true
                        asyncUpdateData({ current: page, pageSize: pageSize, })
                    }
                }}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }} />
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="用户详情">
                    <Descriptions.Item label="NID" span={3}>
                        <Paragraph
                            copyable={{
                                text: `${logsData.nid}`,
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            <Tag color="default">{logsData.nid}</Tag>
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="用户名" span={3}>{logsData.name}</Descriptions.Item>
                    <Descriptions.Item label="账户余额" span={3}>{logsData.integral}</Descriptions.Item>
                    <Descriptions.Item label="会员级别" span={3}>{logsData.config ? logsData.config.member : ''}</Descriptions.Item>
                    <Descriptions.Item label="邮箱地址" span={3}>{logsData.mail}</Descriptions.Item>
                    <Descriptions.Item label="第三方绑定状态" span={3}>
                        {logsData.openid ? <Tag color="success">已绑定</Tag> : <Tag color="error">未绑定</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="状态" span={3}>
                        {logsData.state === 1 ? <Tag color="success">正常</Tag> : <Tag color="error">异常</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="会员过期时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {logsData.config ? logsData.config.etime : ''}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="最后登录时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {logsData.etime}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="创建时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {logsData.ctime}
                        </Tag>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </div>
    )
}



export default Users;