import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Alert, Input, Table, message, Button, Drawer, Tag, Typography, Descriptions, Select } from 'antd'


const { Paragraph, Text } = Typography
const { TextArea } = Input

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '资源名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '开发语言',
        key: 'language',
        dataIndex: 'language',
        render: ((tag) => {
            return (
                <Tag color="processing">{tag ? tag === 'js' ? 'Js' : 'Lua' : 'Lua'}</Tag>
            )
        }),
    },
    {
        title: '使用数',
        dataIndex: 'useNum',
        key: 'useNum',
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '更新时间',
        dataIndex: 'etime',
        key: 'etime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Tag color="processing" className='clogs-check' data-nid={record.nid}>编辑</Tag>
        ),
    },
];

//  表格 [用户]
const columnsUser = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'NID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '用户名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '购买时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
];

//  控制台-代码托管
function Chg(props) {
    const [data, setData] = useState([])
    const [chgData, setChgData] = useState({ nid: '', name: '', language: '', useNum: 0, code: '', users: [], ctime: '' })
    const [chgAddData, setChgAddData] = useState({ name: '', language: 'js', code: '' })
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(null)

    useEffect(() => {
        let name = 'userChgData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
            setLoading(false)
        }
        api.get('/api/v2/get_hostings')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                    setLoading(false)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const showDrawerAdd = () => {
        setOpenAdd(true)
    }
    const onAddClose = () => {
        setOpenAdd(false)
    }

    function getCode(nid) {
        api.get('/api/v2/get_hostings', {
            params: {
                nid: nid
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    setChgData((data) => ({
                        ...data,
                        code: Data.code,
                    }))
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let nid = parseInt(event.target.dataset.nid)
                let Data = localStorage.getItem('userChgData') ? JSON.parse(localStorage.getItem('userChgData')) : []
                for (let i = 0; i < Data.length; i++) {
                    if (parseInt(Data[i].nid) === nid) {
                        console.log(Data[i]);
                        setChgData(Data[i])
                        getCode(nid)
                    }
                }
                showDrawer()
                return
            }
        }
    }

    function onChange(value) {
        setChgData((data) => ({
            ...data,
            name: value
        }))
    }

    function onCodeChange(e) {
        setChgData((data) => ({
            ...data,
            code: e.target.value
        }))
    }

    function handleChange(value) {
        setChgData((data) => ({
            ...data,
            language: value
        }))
    }


    function handleAddChange(value) {
        setChgAddData((data) => ({
            ...data,
            language: value
        }))
    }

    const onAddChange = useCallback((e) => {
        setChgAddData({
            ...chgAddData,
            [e.target.name]: e.target.value,
        })
    }, [chgAddData])

    const saveData = useCallback(() => {
        let Data = chgData
        api.post('/api/v2/update_hostings', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已保存')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [chgData])

    const sendData = useCallback(() => {
        let Data = chgAddData
        if (Data.name.length <= 0) {
            return message.error('请输入资源名称')
        }
        api.post('/api/v2/add_hostings', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    setOpenAdd(false)
                    message.success('已新建')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [chgAddData])


    return (
        <div>
            <Alert message="暂时不支持 Lua 开发语言托管" type="warning" style={{ marginBottom: 20 }} />
            <Button onClick={showDrawerAdd} style={{ float: 'right', marginBottom: 20 }}>新建托管</Button>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={loading}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }}
            />
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="代码托管-详情">
                    <Descriptions.Item label="托管ID" span={3}>
                        <Paragraph
                            copyable={{
                                text: `${chgData.nid}`,
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            <Tag color="default">{chgData.nid}</Tag>
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="资源名称" span={3}>
                        <Paragraph
                            editable={{
                                onChange: onChange,
                            }}
                            style={{ margin: 0 }}
                        >
                            {chgData.name}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="开发语言" span={3}>
                        <Select
                            value={chgData.language}
                            style={{
                                width: 120,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'js',
                                    label: 'Js',
                                },
                                {
                                    value: 'lua',
                                    label: 'Lua',
                                },
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="使用数" span={3}>
                        <Tag color="processing">{chgData.useNum}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="代码内容" span={3}>
                        <TextArea rows={8} value={chgData.code} onChange={onCodeChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="生成时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {chgData.ctime}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={saveData} >保存</Button>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Table
                            columns={columnsUser}
                            dataSource={chgData.users}
                            pagination={false}
                            style={{ width: '100%' }}
                            onRow={(record) => {
                                return {
                                    onClick: onClick, // 点击
                                }
                            }}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>

            <Drawer width={640} placement="right" closable={false} onClose={onAddClose} open={openAdd}>
                <Descriptions title="代码托管-新建">
                    <Descriptions.Item label="资源名称" span={3}>
                        <Input placeholder="请输入资源名称" value={chgAddData.name} name='name' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="开发语言" span={3}>
                        <Select
                            value={chgAddData.language}
                            style={{
                                width: 120,
                            }}
                            onChange={handleAddChange}
                            options={[
                                {
                                    value: 'js',
                                    label: 'Js',
                                },
                                {
                                    value: 'lua',
                                    label: 'Lua',
                                },
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="代码内容" span={3}>
                        <TextArea rows={8} value={chgAddData.code} name='code' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={sendData} >新建</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>


        </div>
    )
}

export default Chg;