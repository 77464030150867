import React, { useState, useEffect } from 'react'
import api from '../api'

import { Typography, message, Avatar } from 'antd'

import { ProCard } from '@ant-design/pro-components'
const { Text } = Typography


//  控制台-粉丝列表
function Flist(props) {
    const [data, setData] = useState([])

    useEffect(() => {
        let name = 'userFlistData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/get_flist')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])

    return (
        <div>
            {
                data.map((i, key) => {
                    return (
                        <ProCard key={key} style={{ width: '30%', float: 'left', margin: 10 }} bordered>
                            <div style={{ float: 'left' }}>
                                <a href={`/u/${i.nid}`}><Avatar size={64} src={<img src={i.avatar} alt="avatar" />} /></a>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Text style={{ marginLeft: 10, fontSize: 20 }}>{i.name}</Text>
                            </div>
                        </ProCard>
                    )
                })
            }
        </div>
    )
}

export default Flist;