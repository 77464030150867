import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { SyncOutlined } from '@ant-design/icons'
import { Table, message, Button, Select, Input, Tag } from 'antd'

const { Search } = Input

let url = 'http://localhost:3000'

if (process.env.NODE_ENV === 'production') {
    url = 'https://www.n2yun.com.cn'
}


//  表格
const columns = [
    {
        title: 'NID',
        dataIndex: 'nid',
        key: 'nid',
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        render: ((state) => {
            let Data = parseInt(state)
            if (Data === 1) {
                return (
                    <Tag color="success">已审核</Tag>
                )
            } else if (Data === 2) {
                return (
                    <Tag color="error">隐藏</Tag>
                )
            } else {
                return (
                    <Tag color="processing">未审核</Tag>
                )
            }
        }),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        dataIndex: 'data',
        key: 'data',
        render: ((data, record) => {
            return (
                <Tag color="processing" className='clogs-check'>
                    <a href={url + `/t/${record.nid}`}>访问</a>
                </Tag>
            )
        }),
    },
];


//  控制台-文章管理
function Articleadmin(props) {
    const [data, setData] = useState({ total: 0, Data: [], Types: [], userConfig: {} })
    const [loading, setLoading] = useState(false)
    const [fc, setFc] = useState(undefined)
    const [type, setType] = useState(undefined)
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
    })

    useLayoutEffect(() => {
        let name = 'userArticleadminData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }
        api.get('/api/v2/admin/article', {
            params: {
                range: page.current,
                max: page.pageSize,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])


    //  异步请求拉取并更新数据
    const asyncUpdateData = useCallback((data, fcData, typeData, refresh) => {
        let name = 'userArticleadminData'
        let Data = data ? data : page
        let Fc = fcData ? fcData : fc
        let Type = typeData ? typeData : typeData === 0 ? typeData : type

        setLoading(true)
        api.get('/api/v2/admin/article', {
            params: {
                range: Data.current,
                max: Data.pageSize,
                fc: Fc,
                type: Type,
                refresh: refresh,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                    setLoading(false)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [page, fc, type])

    const onSearch = ((value) => {
        setFc(value)
        asyncUpdateData({
            current: 1,
            pageSize: 10,
        }, value ? value : 'undefined')
    })

    const handleChange = ((value) => {
        setType(value)
        asyncUpdateData(null, null, value)
    })

    const Refresh = () => {
        asyncUpdateData(null, null, null, true)
    }

    return (
        <div>
            <Search
                placeholder="搜索..."
                onSearch={onSearch}
                style={{
                    width: 260,
                    float: 'right',
                    marginBottom: 20
                }}
            />
            <Select
                placeholder='选择状态'
                style={{
                    float: 'right',
                    width: 120,
                    marginBottom: 20,
                    marginRight: 10
                }}
                onChange={handleChange}
                options={data.Types}
            />
            <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
            <Table
                columns={columns}
                dataSource={data.Data}
                loading={loading}
                pagination={{
                    total: data.total,
                    current: page.current,
                    pageSize: page.pageSize,
                    showSizeChanger: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total) => {
                        return '总计 ' + total + ' 条数据'
                    },
                    onShowSizeChange: (current, size) => {
                        setPage({
                            current: current,
                            pageSize: size,
                        })
                        asyncUpdateData({ current: current, pageSize: size, })
                    },
                    onChange: (page, pageSize) => {
                        setPage({
                            current: page,
                            pageSize: pageSize,
                        })
                        asyncUpdateData({ current: page, pageSize: pageSize, })
                    }
                }} />
        </div>
    )
}



export default Articleadmin;