import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Input, Table, message, Button, Drawer, Tag, Typography, Descriptions, Tooltip } from 'antd'

const { Paragraph } = Typography
const { TextArea } = Input

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: ((id) => {
            return (
                <Paragraph
                    copyable={{
                        text: `${id}`,
                    }}
                    style={{ margin: 0 }}
                >
                    <Tooltip title={id}>
                        <Tag color="default">{id}</Tag>
                    </Tooltip>
                </Paragraph>
            )
        }),
    },
    {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Tag color="processing" className='clogs-check' data-id={record.id}>编辑</Tag>
        ),
    },
];

//  控制台-KOOK卡片托管
function Kcard(props) {
    const [data, setData] = useState([])
    const [KcardData, setKcardData] = useState({ id: '', name: '', code: '', ctime: '' })
    const [KcardAddData, setKcardAddData] = useState({ name: '', code: '' })
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [update, setUpdate] = useState(null)
    const [name, setName] = useState('')


    useLayoutEffect(() => {
        let name = 'userKcardData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/get_kook_card')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const showDrawerAdd = () => {
        setOpenAdd(true)
    }
    const onAddClose = () => {
        setOpenAdd(false)
    }


    const onChange = useCallback((e) => {
        setKcardData({
            id: KcardData.id,
            name: KcardData.name,
            code: e.target.value,
            ctime: KcardData.ctime,
        })
    }, [KcardData])


    const onAddChange = useCallback((e) => {
        if (e.target.name === 'name') {
            setKcardAddData({
                name: e.target.value,
                code: KcardAddData.code,
            })
        }
        if (e.target.name === 'code') {
            setKcardAddData({
                name: KcardAddData.name,
                code: e.target.value,
            })
        }
    }, [KcardAddData])

    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let id = parseInt(event.target.dataset.id)
                let Data = localStorage.getItem('userKcardData') ? JSON.parse(localStorage.getItem('userKcardData')) : []
                for (let i = 0; i < Data.length; i++) {
                    if (Data[i].id === id) {
                        setKcardData(Data[i])
                        setName(Data[i].name)
                    }
                }
                showDrawer()
                return
            }
        }
    }

    const saveKcard = useCallback(() => {
        let Data = KcardData
        Data.name = name

        api.post('/api/v2/update_kook_card', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已保存,请重启你的插件来获取最新数据')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [name, KcardData])

    const addKcard = useCallback(() => {
        let Data = KcardAddData
        if (Data.name.length <= 0) {
            return message.error('请输入卡片名称')
        }
        Data.add = true

        api.post('/api/v2/update_kook_card', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    setOpenAdd(false)
                    setKcardAddData({ name: '', code: '' })
                    message.success('已新建,请重启你的插件来获取最新数据')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [KcardAddData])

    return (
        <div>
            <Button onClick={showDrawerAdd} style={{ float: 'right', marginBottom: 20 }}>新建卡片</Button>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }}
            />
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="KOOK卡片-详情">
                    <Descriptions.Item label="卡片ID" span={3}>
                        <Paragraph
                            copyable={{
                                text: `${KcardData.id}`,
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            <Tag color="default">{KcardData.id}</Tag>
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="卡片名称" span={3}>
                        <Paragraph
                            editable={{
                                onChange: setName,
                            }}
                            style={{ margin: 0 }}
                        >
                            {name}
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="代码内容" span={3}>
                        <TextArea rows={8} value={KcardData.code} onChange={onChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="生成时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {KcardData.ctime}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={saveKcard} >保存</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
            <Drawer width={640} placement="right" closable={false} onClose={onAddClose} open={openAdd}>
                <Descriptions title="KOOK卡片-新建">
                    <Descriptions.Item label="卡片名称" span={3}>
                        <Input placeholder="请输入名称" value={KcardAddData.name} name='name' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="代码内容" span={3}>
                        <TextArea rows={8} value={KcardAddData.code} name='code' onChange={onAddChange} />
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={addKcard} >新建</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </div>
    )
}

export default Kcard;