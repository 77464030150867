import React, { useState, useLayoutEffect } from 'react'
import api from './api'
import '../css/User.css'


import { useParams } from 'react-router-dom'

import { HeartTwoTone } from '@ant-design/icons'

import { Image, Table, Typography, Tag, Avatar, Divider, message } from 'antd'

import dayjs from 'dayjs'

const { Text, Title, Link } = Typography

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: ((title, _g) => {
            return (
                <Link href={`/t/${_g.nid}`}>{title}</Link>
            )
        }),
    },
    {
        title: '类型',
        dataIndex: 'category',
        key: 'category',
        render: ((category) => {
            return (
                <Tag color="processing">{category}</Tag>
            )
        }),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
];

//  用户个人页面布局
function User(props) {
    const id = useParams()
    const [data, setData] = useState({ article: [] })

    useLayoutEffect(() => {
        let name = 'publicUsertData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/user/public/' + id.id)
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])


    function relativeTime(time) {
        let relativeTime = require('dayjs/plugin/relativeTime')
        dayjs.extend(relativeTime)
        return dayjs(time).fromNow(true)
    }

    function diffInDays(time) {
        const dateStr = time
        const diffInMs = dayjs().diff(dayjs(dateStr))
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
        return diffInDays
    }


    return (
        <div>
            <Image
                width='100%'
                height={400}
                src={data.bimg}
            />
            <div style={{ float: 'left', marginTop: 20 }}>
                <Avatar size={100} src={<img src={data.avatar} alt="avatar" />} />
            </div>
            <div style={{ marginTop: 20 }}>
                <Text style={{ marginLeft: 20, fontSize: 26 }}>{data.name}</Text>
            </div>
            <div >
                <Tag color="processing" style={{ marginLeft: 20, marginBottom: 10 }}>NID: {data.nid}</Tag>
            </div>
            <div >
                <Text style={{ marginLeft: 20 }}>{data.sign}</Text>
            </div>
            <Divider style={{ marginBottom: 4 }} />
            <div>
                <Text style={{ marginLeft: 20 }} type="secondary">加入日期:<Text > {dayjs(data.ctime).format('YYYY-MM-DD')}</Text></Text>
                <Text style={{ marginLeft: 20 }} type="secondary">认证:<Text > {data.cern}</Text></Text>
                <Text style={{ marginLeft: 20 }} type="secondary">最后活动:<Text > {relativeTime(data.etime)}</Text></Text>
                <Text style={{ marginLeft: 20 }} type="secondary">用户组:<Text > {data.userGroup}</Text></Text>
            </div>
            <Divider style={{ marginTop: 4 }} />
            <Title level={4}>统计信息</Title>
            <ul className='Statistics'>
                <li>
                    <Title level={4} >{diffInDays(data.ctime)} <Text type="secondary">已加入天数</Text></Title>
                </li>
                <li>
                    <Title level={4} >{data.article.length} <Text type="secondary">创建的话题</Text></Title>
                </li>
                <li>
                    <Title level={4} >{data.reply}  <Text type="secondary">创建的帖子</Text></Title>
                </li>
                <li>
                    <Title level={4} >{data.watch}  <Text type="secondary">浏览的话题</Text></Title>
                </li>
                <li>
                    <Title level={4} >{data.glike}  <Text type="secondary"> <HeartTwoTone /> 已送出</Text></Title>
                </li>
                <li>
                    <Title level={4} >{data.like}  <Text type="secondary"> <HeartTwoTone /> 已收到</Text></Title>
                </li>
            </ul>
            <props.clear />
            <Table
                columns={columns}
                dataSource={data.article}
                pagination={false} />
        </div>
    )
}

export default User;