import React, { useState } from 'react'

import { InboxOutlined } from '@ant-design/icons'
import { Typography, message, Upload, Alert } from 'antd'

import { ProCard } from '@ant-design/pro-components'
import s3 from '../s3'

const { Paragraph, Title } = Typography
const { Dragger } = Upload

//  控制台-视频/图片托管
function Vihg(props) {
    const [data, setData] = useState('无')

    function isImage(file) {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp']
        return file && acceptedImageTypes.includes(file)
    }

    //  文件上传
    const uploadProps = {
        name: 'bed',
        maxCount: 1,
        style: {
            marginTop: 20
        },
        customRequest(info) {
            if (!isImage(info.file.type)) {
                message.error('请上传图片类型的文件,只支持gif、jpeg、png、webp等多种的图片格式')
                info.onError()
                return
            }
            if (info.file.size > 12457280) {
                message.error('文件大小超过10MB,无法上传')
                info.onError()
                return
            }
            s3.upload({
                type: 'bed',
                file: info.file,
                name: info.file.name,
                size: info.file.size,
            }, (msg) => {
                setData(msg.url)
                info.onSuccess()
                message.success('上传成功')
            })
        },
    }

    return (
        <div>
            <Alert
                message="结束限免"
                description="此服务已结束免费使用阶段, 目前暂停使用, 已托管的图片不受影响"
                type="warning"
                showIcon
                style={{ marginBottom: 20 }}
            />

            {
                props.verifyAy('admin') ?
                    <ProCard style={{ width: '100%' }} bordered>
                        <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 图片托管</Title>
                        <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                            <p className="ant-upload-hint">
                                仅支持大小为10MB以内的单个文件上传,请遵守中国法律法规,禁止上传色情等违法图片。
                            </p>
                        </Dragger>
                        <div style={{ marginTop: 20 }}>
                            <Paragraph copyable={{ text: data }}>外链地址: {data}</Paragraph>
                        </div>
                    </ProCard>
                    : null
            }

        </div >
    )
}

export default Vihg;