import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { } from 'react-router-dom'

import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Table, message, Button, Input, Tag, Tooltip, Typography, Drawer, Descriptions, Select } from 'antd';

const { Paragraph, Text, Link } = Typography
const { Search } = Input

//  表格
const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '用户名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '封禁原因',
        dataIndex: 'reason',
        key: 'reason',
        render: ((reason, _g) => {
            if (_g.type === '1') {
                return (
                    <Tag color="success">正常</Tag>
                )
            }
            return (
                <Tooltip title={reason}>
                    <Tag color="error">原因</Tag>
                </Tooltip>
            )
        }),
    },
    {
        title: '创建时间',
        dataIndex: 'ctime',
        key: 'ctime',
    },
    {
        title: '操作',
        dataIndex: 'data',
        key: 'data',
        render: ((data, record, index) => {
            let msg = ''
            for (const key in data) {
                msg = msg + '[' + key + ']: ' + data[key] + '\n'
            }
            return (
                <>
                    <Tag color="processing" className='clogs-check' data-_id={record._id}>管理</Tag>
                </>
            )
        }),
    },
];


//  控制台-云联Ban
function Cban(props) {
    const [data, setData] = useState({ total: 0, Data: [], Types: [] })
    const [logsData, setLogsData] = useState({})
    const [CbanData, setCbanData] = useState({})
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fc, setFc] = useState(undefined)
    const [update, setUpdate] = useState(null)
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
    })

    useLayoutEffect(() => {
        let name = 'userCbanData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/get_cloud_ban', {
            params: {
                range: page.current,
                max: page.pageSize,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const showDrawerAdd = () => {
        setOpenAdd(true)
    }
    const onAddClose = () => {
        setOpenAdd(false)
    }


    function onClick(event) {
        let name = event.target.className
        let arr = name.split(/\s+/)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 'clogs-check') {
                let _id = event.target.dataset._id
                let Data = localStorage.getItem('userCbanData') ? JSON.parse(localStorage.getItem('userCbanData')).Data : []
                for (let i = 0; i < Data.length; i++) {
                    if (Data[i]._id === _id) {
                        setLogsData(Data[i])
                    }
                }
                showDrawer()
                return
            }
        }
    }

    //  异步请求拉取并更新数据
    const asyncUpdateData = useCallback((data, fcData, refresh) => {
        let name = 'userCbanData'
        let Data = data ? data : page
        let Fc = fcData ? fcData : fc

        setLoading(true)
        api.get('/api/v2/get_cloud_ban', {
            params: {
                range: Data.current,
                max: Data.pageSize,
                fc: Fc,
                refresh: refresh,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                    setLoading(false)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [page, fc])

    const onSearch = ((value) => {
        setFc(value)
        asyncUpdateData(null, value ? value : 'undefined')
    })

    const Refresh = () => {
        asyncUpdateData(null, null, true)
    }

    const onChange = useCallback((e) => {
        setCbanData({
            ...CbanData,
            [e.target.name]: e.target.value,
        })
    }, [CbanData])

    function handleChange(value) {
        setCbanData({
            ...CbanData,
            value: value,
        })
    }


    function addCban() {
        let Data = CbanData
        if (!Data.game_id) {
            return message.error('请输入封禁ID')
        }
        if (!Data.reason && Data.value === '0') {
            return message.error('请输入封禁原因')
        }
        api.post('/api/v2/add_cloud_ban', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    setOpenAdd(false)
                    setCbanData({})
                    message.success('已添加,正在同步全网')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    return (
        <div>
            <Search
                placeholder="搜索玩家名字、封禁ID"
                onSearch={onSearch}
                style={{
                    width: 260,
                    float: 'right',
                    marginBottom: 20
                }}
            />
            <Button style={{ float: 'right', marginBottom: 20, marginRight: 10 }} onClick={showDrawerAdd}>添加数据</Button>
            <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
            <Table
                columns={columns}
                dataSource={data.Data}
                loading={loading}
                pagination={{
                    total: data.total,
                    current: page.current,
                    pageSize: page.pageSize,
                    showSizeChanger: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total) => {
                        return '总计 ' + total + ' 条数据'
                    },
                    onShowSizeChange: (current, size) => {
                        setPage({
                            current: current,
                            pageSize: size,
                        })
                        asyncUpdateData({ current: current, pageSize: size, })
                    },
                    onChange: (page, pageSize) => {
                        setPage({
                            current: page,
                            pageSize: pageSize,
                        })
                        asyncUpdateData({ current: page, pageSize: pageSize, })
                    }
                }}
                onRow={(record) => {
                    return {
                        onClick: onClick, // 点击
                    }
                }} />
            <Drawer width={640} placement="right" closable={false} onClose={onClose} open={open}>
                <Descriptions title="云联BAN-详情">
                    <Descriptions.Item label="唯一ID" span={3}>
                        <Paragraph
                            copyable={{
                                text: `${logsData._id}`,
                            }}
                            style={{ marginBottom: 0 }}
                        >
                            <Tag color="default">{logsData._id}</Tag>
                        </Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label="用户名" span={3}>{logsData.name}</Descriptions.Item>
                    <Descriptions.Item label="封禁原因" span={3}>{logsData.reason}</Descriptions.Item>
                    <Descriptions.Item label="封禁ID列表" span={3}>
                        <ul style={{ padding: 5, paddingLeft: 0 }}>
                            {
                                logsData.data ? logsData.data.map((i, key) => {
                                    return (
                                        <li key={i} style={{ listStyle: 'none', marginTop: 10 }}>
                                            <Tag color="processing" >{i}</Tag>
                                        </li>
                                    )
                                })
                                    : ''}
                        </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="生成时间" span={3}>
                        <Tag icon={<ClockCircleOutlined />} color="default">
                            {logsData.ctime}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item span={3}>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
            <Drawer width={640} placement="right" closable={false} onClose={onAddClose} open={openAdd}>
                <Descriptions title="云联BAN-添加数据">
                    <Descriptions.Item label="封禁ID" span={3}>
                        <Input placeholder="请输入封禁ID" value={CbanData.game_id} name='game_id' size='small' onChange={onChange} />
                    </Descriptions.Item>
                    <Descriptions.Item label="身份组" span={3}>
                        <Select
                            defaultValue="0"
                            style={{
                                width: 120,
                            }}
                            size='small'
                            onChange={handleChange}
                            options={[
                                {
                                    value: '0',
                                    label: '封禁',
                                },
                                {
                                    value: '1',
                                    label: '解开',
                                },
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="封禁原因" span={3} style={{ display: `${CbanData.value === '1' ? 'none' : ''}` }}>
                        <Input placeholder="请输入封禁原因" value={CbanData.reason} name='reason' size='small' onChange={onChange} />
                    </Descriptions.Item>
                    <Descriptions.Item span={3} >
                        <Button type="primary" size='small' onClick={addCban} >添加数据</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </div>
    )
}



export default Cban;