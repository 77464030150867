import React, { useState } from 'react'
import '../css/Console.css'

import { useParams, useNavigate } from 'react-router-dom'

import {
    ProfileOutlined,
    SettingOutlined,
    UserOutlined,
    AlertOutlined,
    BarsOutlined
} from '@ant-design/icons'
import {
    Layout,
    Menu,
    Alert,
    Result
} from 'antd'


import Users from './admin/Users'
import Articleadmin from './admin/Articleadmin'
import Cban from './admin/Cban'
import Scriptadmin from './admin/Scriptadmin'
import Rsadmin from './admin/Rsadmin'
import Webset from './admin/Webset'
import NzadminSet from './admin/NzadminSet'

const { Sider } = Layout

//  管理站务-控制台布局
function Admin(props) {
    const id = useParams().id
    const navigate = useNavigate()
    const [data, setData] = useState(id)
    const [items] = useState([
        getItem('网站用户', 'users', <UserOutlined />, null, null, 'admin'),
        getItem('文章管理', 'articleadmin', <ProfileOutlined />, null, null, 'admin'),
        getItem('云联Ban', 'cban', <AlertOutlined />, null, null, 'admin'),
        getItem('插件中心', 'rs', <BarsOutlined />, [
            getItem('资源', 'scriptadmin'),
            getItem('依赖项', 'rsadmin'),
        ], null, 'admin'),
        getItem('网站设置', 'webset', <SettingOutlined />, null, null, 'admin'),
        getItem('Nzadmin 设置', 'nzadminset', <SettingOutlined />, null, null, 'admin'),
    ])
    const [width] = useState(window.innerWidth)

    //  监听选项回调
    function MenuClick(item) {
        navigate('/admin/' + item.key)
        setData(item.key)
    }

    //  处理子页面
    function Content() {
        if (!props.state.token) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="请登录再访问页面"
                />
            )
        }

        if (props.state.user.suser) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="你没有这权限访问"
                />
            )
        }

        if (!props.verifyAy('admin')) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="你没有这权限访问"
                />
            )
        }

        if (id === 'users') {
            return (
                <Users {...props} />
            )
        }
        if (id === 'webset') {
            return (
                <Webset {...props} />
            )
        }
        if (id === 'rsadmin') {
            return (
                <Rsadmin {...props} />
            )
        }
        if (id === 'scriptadmin') {
            return (
                <Scriptadmin {...props} />
            )
        }
        if (id === 'nzadminset') {
            return (
                <NzadminSet {...props} />
            )
        }
        if (id === 'cban') {
            return (
                <Cban {...props} />
            )
        }
        if (id === 'articleadmin') {
            return (
                <Articleadmin {...props} />
            )
        }
        return (
            <Result
                status="404"
                title="404"
                subTitle="您访问的页面已经跑路了"
            />
        )
    }

    function getItem(label, key, icon, children, type, ay) {
        if (ay) {
            if (props.verifyAy(ay)) {
                return {
                    key,
                    icon,
                    children,
                    label,
                    type,
                }
            }
        } else {
            return {
                key,
                icon,
                children,
                label,
                type,
            }
        }
    }

    const MemoizedContent = React.memo(Content)

    if (props.state.token) {
        return (
            <div className='console'>
                <Layout>
                    <Layout>
                        <Sider
                            width={200}
                        >
                            <Menu
                                mode="vertical"
                                selectedKeys={[data]}
                                style={{
                                    height: '100%',
                                    borderRight: 0,
                                }}
                                items={items}
                                onSelect={MenuClick}
                            />
                        </Sider>
                        <Layout
                            style={{
                                padding: '0 24px 24px',
                                backgroundColor: '#fff',
                                width: (Math.floor(width * 0.6) - 200)
                            }}
                        >
                            <Alert message="欢迎使用基于N2YUN网站开发的可视化控制台" type="info" />
                            <div className='appContent'>
                                <MemoizedContent {...props} />
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        )
    } else {
        return (
            <Result
                status="403"
                title="403"
                subTitle="请登录再访问页面"
            />
        )
    }
}

export default Admin;