import React, { useState, useEffect } from 'react'
import api from './api'
import '../css/Login.css'

import Logo from '../images/logo.png'
import { useNavigate, Link } from 'react-router-dom'

import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons'
import { Button, message, Form, Input } from 'antd'
import md5 from 'js-md5'


//  注册布局
function Register(props) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (props.state.token) {
            navigate('/')
            return
        }
    }, [])

    //  跳转
    function loginAction() {
        var redirect = "/login"
        navigate(redirect, { replace: true })
    }

    //  提交注册
    function onFinish(values) {
        const key = Date
        message.loading({ key, content: '正在注册中...', })
        setLoading(true)
        api.post('/api/v2/user/register', {
            email: values.email,
            name: values.username,
            password: md5(values.password),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success({ key, content: '注册成功,请前往邮箱查看并进行账户验证' })
                    loginAction()
                } else {
                    message.error({ key, content: res.data.data.text })
                    setLoading(false)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
                setLoading(false)
            })
    };

    return (
        <div className='login'>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div className='logo'>
                    <Link to="/">
                        <img src={Logo} alt='logo'></img>
                    </Link>
                </div>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: '请输入正确的邮箱地址!',
                        },
                    ]}
                >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="邮箱" />
                </Form.Item>
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入用户名!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>注册</Button>
                    <a href="/login">已有账户? 点击登录</a>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Register