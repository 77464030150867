import React, { useEffect, useState } from 'react'
import api from './api'
import '../css/github-markdown.css'

import { DownloadOutlined } from '@ant-design/icons'

import { Image, message, Tag, Typography, Timeline, Button } from 'antd'

import { ProCard } from '@ant-design/pro-components'

const { Text, Title, Link } = Typography


const html = '内容加载中...'

function Get() {
    let name = 'userNzadminData'
    if (localStorage.getItem(name)) {
        return {
            ...JSON.parse(localStorage.getItem(name)),
            html: window.markdown.toHTML(JSON.parse(localStorage.getItem(name)).content)
        }
    } else {
        return { html: window.markdown.toHTML(html) }
    }
}

//  nzadmin 布局
function Nzadmin(props) {
    const [data, setData] = useState(Get())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let name = 'userNzadminData'
        api.get('/api/v2/nzadmin/get')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data
                    setData({
                        ...Data,
                        html: window.markdown.toHTML(Data.content)
                    })
                    localStorage.setItem(name, JSON.stringify(Data))
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])


    function download() {
        setLoading(true)
        api.get('/api/v2/nzadmin/download/')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    const link = document.createElement('a')
                    link.href = Data
                    link.click()
                    setLoading(false)
                    message.success('已经开始下载了')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    return (
        <div>
            <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Title level={2}>NZ - admin <Tag color="warning">v{data.version} 版本</Tag> <Tag color="#AA00AA" >会员专享</Tag></Title>
                <Title level={5}>新一代的 FiveM 服务器管理面板, 致力于打造更方便的管理体验</Title>

                <div><Text><Tag color="processing">{data.claim}</Tag></Text></div>


                <Button type="primary" icon={<DownloadOutlined />} size='large' style={{ marginTop: 20 }} onClick={download} loading={loading}>
                    v{data.version} 版本
                </Button>
            </div>
            <ProCard bordered headerBordered>
                <Image
                    src={data.imgsrc}
                />
                <div className='markdown-body' style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: data.html }}></div>
            </ProCard>
        </div>
    )
}

export default Nzadmin