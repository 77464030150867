import React, { useState } from 'react'
import '../css/Console.css'

import { useParams, useNavigate } from 'react-router-dom'

import { ShareAltOutlined, SettingOutlined, PieChartOutlined, PayCircleOutlined, UserOutlined, AppstoreOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Layout, Menu, Alert, Result } from 'antd'

import Home from './app/Home'
import Member from './app/Member'
import Aan from './app/Aan'
import Over from './app/Over'
import Chg from './app/Chg'
import Kcard from './app/Kcard'
// import Kbot from './app/Kbot'
import Aws from './app/Aws'
import Nzcos from './app/Nzcos'
import AwsConfig from './app/AwsConfig'
// import Workorder from './app/Workorder'
import Wlist from './app/Wlist'
import Flist from './app/Flist'
import Vihg from './app/Vihg'
import Set from './app/Set'

const { Sider } = Layout

//  控制台布局
function Console(props) {
    const id = useParams().id
    const navigate = useNavigate()
    const [data, setData] = useState(id)
    const [items, setItems] = useState([
        getItem('总览', 'home', <PieChartOutlined />),
        getItem('开放平台', 'open', <ShareAltOutlined />, [
            getItem('代码托管', 'chg'),
            // getItem('一键汉化', 'csn'),
            getItem('一键去墙', 'aws'),
            getItem('一键去墙 [配置]', 'awsconfig', null, null, null, 'admin'),
            getItem('NZ云存储', 'nzcos'),
            getItem('图片托管', 'vihg'),
            getItem('[KOOK] 卡片托管', 'kcard'),
            // getItem('[KOOK] Bot管理', 'kbot'),
        ]),
        getItem('会员中心', 'member', <UserOutlined />),
        getItem('余额中心', 'over', <PayCircleOutlined />),
        getItem('资产授权', 'aan', <AppstoreOutlined />),
        getItem('关注/粉丝', 'wf', <UserSwitchOutlined />, [
            getItem('已关注列表', 'wlist'),
            getItem('粉丝列表', 'flist'),
        ]),
        getItem('设置', 'set', <SettingOutlined />),
        // getItem('工单系统', 'workorder', <BranchesOutlined />, null, null, 'admin'),
    ])
    const [width] = useState(window.innerWidth)


    //  监听选项回调
    function MenuClick(item) {
        navigate('/app/' + item.key)
        setData(item.key)
    }

    //  处理子页面
    function Content() {
        if (!props.state.token) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="请登录再访问页面"
                />
            )
        }

        if (props.state.user.suser) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="你没有这权限访问"
                />
            )
        }

        if (id === 'home') {
            return (
                <Home {...props} />
            )
        }
        if (id === 'member') {
            return (
                <Member {...props} />
            )
        }
        if (id === 'aan') {
            return (
                <Aan {...props} />
            )
        }
        if (id === 'over') {
            return (
                <Over {...props} />
            )
        }
        if (id === 'chg') {
            return (
                <Chg {...props} />
            )
        }
        if (id === 'kcard') {
            return (
                <Kcard {...props} />
            )
        }
        // if (id === 'kbot') {
        //     return (
        //         <Kbot {...props} />
        //     )
        // }
        if (id === 'aws') {
            return (
                <Aws {...props} />
            )
        }
        if (id === 'nzcos') {
            return (
                <Nzcos {...props} />
            )
        }
        if (id === 'awsconfig') {
            return (
                <AwsConfig {...props} />
            )
        }
        // if (id === 'workorder') {
        //     return (
        //         <Workorder {...props} />
        //     )
        // }
        if (id === 'wlist') {
            return (
                <Wlist {...props} />
            )
        }
        if (id === 'flist') {
            return (
                <Flist {...props} />
            )
        }
        if (id === 'set') {
            return (
                <Set {...props} />
            )
        }
        if (id === 'vihg') {
            return (
                <Vihg {...props} />
            )
        }
        return (
            <Result
                status="404"
                title="404"
                subTitle="您访问的页面已经跑路了"
            />
        )
    }

    function getItem(label, key, icon, children, type, ay) {
        if (ay) {
            if (props.verifyAy(ay)) {
                return {
                    key,
                    icon,
                    children,
                    label,
                    type,
                }
            }
        } else {
            return {
                key,
                icon,
                children,
                label,
                type,
            }
        }
    }

    const MemoizedContent = React.memo(Content)

    if (props.state.token) {
        return (
            <div className='console'>
                <Layout>
                    <Layout>
                        <Sider
                            width={200}
                        >
                            <Menu
                                mode="vertical"
                                selectedKeys={[data]}
                                style={{
                                    height: '100%',
                                    borderRight: 0,
                                }}
                                items={items}
                                onSelect={MenuClick}
                            />
                        </Sider>
                        <Layout
                            style={{
                                padding: '0 24px 24px',
                                backgroundColor: '#fff',
                                width: (Math.floor(width * 0.6) - 200)
                            }}
                        >
                            <Alert message="欢迎使用基于N2YUN网站开发的可视化控制台" type="info" />
                            <div className='appContent'>
                                <MemoizedContent {...props} />
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        )
    } else {
        return (
            <Result
                status="403"
                title="403"
                subTitle="请登录再访问页面"
            />
        )
    }
}

export default Console;