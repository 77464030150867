import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { SyncOutlined } from '@ant-design/icons'
import { Typography, message, Input, Button, Space, DatePicker } from 'antd'

import { ProCard } from '@ant-design/pro-components'
const { Text, Title } = Typography
const { TextArea } = Input

//  控制台- Nzadmin设置
function NzadminSet(props) {
    const [data, setData] = useState({})
    const [update, setUpdate] = useState(null)

    useEffect(() => {
        let name = 'userNodeMsgData'
        if (localStorage.getItem(name)) {
            setData({
                ...data,
                ...JSON.parse(localStorage.getItem(name))
            })
        }
        api.get('/api/v2/admin/get?name=nzadmin')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData({
                        ...data,
                        ...Data
                    })
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    function onChange(e) {
        let name = e.target.name
        let value = e.target.value
        setData({
            ...data,
            [name]: value
        })
    }

    function updateData(name) {
        if (!data[name]) {
            return message.error('请输入内容!')
        }

        api.post('/api/v2/admin/updatae?name=nzadmin', {
            data: JSON.stringify({
                name: name,
                value: data[name],
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('数据已更新')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    return (
        <div>
            <ProCard style={{ width: '100%', marginBottom: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 基础信息</Title>
                <div style={{ marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 600
                        }}
                    >
                        <Input placeholder="版本号" addonBefore="版本号" name='version' value={data.version} onChange={onChange} />
                        <Button type="primary" onClick={() => { updateData('version') }}>更新</Button>
                    </Space.Compact>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 600
                        }}
                    >
                        <Input placeholder="要求" addonBefore="要求" name='claim' value={data.claim} onChange={onChange} />
                        <Button type="primary" onClick={() => { updateData('claim') }}>更新</Button>
                    </Space.Compact>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 600
                        }}
                    >
                        <Input placeholder="图片链接" addonBefore="图片链接" name='imgsrc' value={data.imgsrc} onChange={onChange} />
                        <Button type="primary" onClick={() => { updateData('imgsrc') }}>更新</Button>
                    </Space.Compact>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Space.Compact
                        style={{
                            width: 600
                        }}
                    >
                        <Input placeholder="下载链接" addonBefore="下载链接" name='downloadsrc' value={data.downloadsrc} onChange={onChange} />
                        <Button type="primary" onClick={() => { updateData('downloadsrc') }}>更新</Button>
                    </Space.Compact>
                </div>
            </ProCard>
            <ProCard style={{ width: '100%', marginBottom: 20 }} bordered>
                <Title level={5} style={{ marginTop: 0, marginBottom: 0, fontWeight: 400 }} > 介绍内容</Title>
                <div style={{ marginTop: 20 }}>
                    <TextArea rows={20} style={{ marginBottom: 20 }} name='content' value={data.content} onChange={onChange} />
                    <Button type="primary" onClick={() => { updateData('content') }} ><SyncOutlined /> 更新介绍内容</Button>
                </div>
            </ProCard>
        </div >
    )
}

export default NzadminSet;