import axios from 'axios'
import AWS from 'aws-sdk'
import md5 from 'js-md5'

var oss = {}
var s3 = null
const Token = 'IqBfMawdecJEib54EWZcud'

//  获取上传批准
function Execute_Oss(data, ck) {
    let baseURL = 'http://192.168.0.156:4000/'

    if (process.env.NODE_ENV === 'production') {
        baseURL = 'https://api-v2.n2yun.com.cn/'
    }

    const ctime = Date.now()
    const sign = md5('/api/v2/Generate_Token' + ctime + Token)

    axios.request({
        url: `/api/v2/Generate_Token?sign=${sign}&signctime=${ctime}`,
        method: 'post',
        baseURL: baseURL,
        data: data
    }).then(
        res => {
            if (res.data.code === 200) {
                let msgs = res.data.data.data.oss_token
                s3 = new AWS.S3({
                    region: 'automatic',
                    endpoint: msgs.s3Endpoint,
                    credentials: msgs.credentials,
                    params: {
                        Bucket: msgs.s3Bucket
                    }
                })
                ck(s3, msgs.file_name)
            } else {
                return
            }
        }, error => {
            return
        }
    )
}

//  上传处理
oss.upload = (data, ck) => {
    Execute_Oss({
        name: data.name,
        type: data.type,
        size: data.size,
    }, (s3, file_name) => {
        var params = {
            Body: data.file,
            Key: file_name,
        }
        s3.putObject(params, function (err, msg) {
            if (err) {
                console.log(err);
                return
            } else {
                ck({
                    url: 'https://cdn.n2yun.com.cn/' + file_name
                })
            }
        })
    })
}

export default oss;