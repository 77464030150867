import React, { useState, useLayoutEffect, useCallback } from 'react'
import api from '../api'

import { useNavigate } from 'react-router-dom'

import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Table, message, Button, Input, Tag, Tooltip, Typography, Drawer, Descriptions, Select } from 'antd';

const { Paragraph, Text, Link } = Typography
const { Search } = Input

let timeoutId = false

//  控制台-依赖项管理
function Rsadmin(props) {
    const navigate = useNavigate()
    const [data, setData] = useState({ total: 0, Data: [], Types: [] })
    const [loading, setLoading] = useState(false)
    const [fc, setFc] = useState(undefined)
    const [update, setUpdate] = useState(null)
    const [page, setPage] = useState({
        current: 1,
        pageSize: 10,
    })

    useLayoutEffect(() => {
        let name = 'userRsadminData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/admin/rs', {
            params: {
                range: page.current,
                max: page.pageSize,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    //  表格
    const columns = [
        {
            title: 'ID',
            dataIndex: 'nid',
            key: 'nid',
        },
        {
            title: '资源名称',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '资源代码',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '介绍',
            dataIndex: 'msg',
            key: 'msg',
            render: ((msg) => (
                <Tooltip title={msg}><Tag>查看</Tag></Tooltip>
            )),
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            render: ((state) => {
                if (state === 1) {
                    return (
                        <Tag color="success">正常</Tag>
                    )
                }
                return (
                    <Tag color="error">下架</Tag>
                )
            }),
        },
        {
            title: '下载次数',
            dataIndex: 'download',
            key: 'download',
        },
        {
            title: '版本号',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '创建时间',
            dataIndex: 'ctime',
            key: 'ctime',
        },
        {
            title: '操作',
            dataIndex: 'data',
            key: 'data',
            render: ((data, record, index) => {
                return (
                    <>
                        <Tag color="processing" className='clogs-check' onClick={() => { onClick(record.nid) }}>管理</Tag>
                        {record.state === 1 ? <Tag color="error" style={{ marginTop: 10 }} className='clogs-check' onClick={() => { onClick(record._id, 'removed') }}>下架</Tag> : <Tag color="success" style={{ marginTop: 10 }} className='clogs-check' onClick={() => { onClick(record._id, 'recover') }}>恢复</Tag>}
                    </>
                )
            }),
        },
    ]


    function onClick(nid, name) {
        if (name) {
            return updateData(nid, name)
        }
        navigate('/rely_edit/' + parseInt(nid))
    }


    function updateData(nid, name) {
        api.post('/api/v2/admin/rs/set', {
            data: JSON.stringify({
                nid: nid,
                name: name
            })
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('已更新数据')
                    setUpdate(Date.now())
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    //  异步请求拉取并更新数据
    const asyncUpdateData = useCallback((data, fcData, refresh) => {
        let name = 'userRsadminData'
        let Data = data ? data : page
        let Fc = fcData ? fcData : fc

        setLoading(true)
        api.get('/api/v2/admin/rs', {
            params: {
                range: Data.current,
                max: Data.pageSize,
                fc: Fc,
                refresh: refresh,
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                    setLoading(false)
                    timeoutId = false
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [page, fc])

    const onSearch = ((value) => {
        setFc(value)
        asyncUpdateData(null, value ? value : 'undefined')
    })

    const Refresh = () => {
        asyncUpdateData(null, null, true)
    }

    return (
        <div>
            <Search
                placeholder="搜索玩家名字、封禁ID"
                onSearch={onSearch}
                style={{
                    width: 260,
                    float: 'right',
                    marginBottom: 20
                }}
            />
            <Button style={{ float: 'right', marginBottom: 20, marginRight: 10 }} onClick={() => { navigate('/rely_edit') }}>添加数据</Button>
            <Button onClick={Refresh} type="primary" style={{ float: 'right', marginBottom: 20, marginRight: 10 }} icon={<SyncOutlined />} />
            <Table
                columns={columns}
                dataSource={data.Data}
                loading={loading}
                pagination={{
                    total: data.total,
                    current: page.current,
                    pageSize: page.pageSize,
                    showSizeChanger: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                    showTotal: (total) => {
                        return '总计 ' + total + ' 条数据'
                    },
                    onShowSizeChange: (current, size) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: current,
                            pageSize: size,
                        })
                        timeoutId = true
                        asyncUpdateData({ current: current, pageSize: size, })
                    },
                    onChange: (page, pageSize) => {
                        if (timeoutId) {
                            return
                        }
                        setPage({
                            current: page,
                            pageSize: pageSize,
                        })
                        timeoutId = true
                        asyncUpdateData({ current: page, pageSize: pageSize, })
                    }
                }}
            />
        </div>
    )
}



export default Rsadmin;