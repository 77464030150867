import React, { useEffect, useState } from 'react'
import api from './api'
import '../css/Login.css'


import { List, message, Tag, Typography, Modal, Divider } from 'antd'
const { Text, Title, Link } = Typography


//  通知布局
function Notify(props) {
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState({ data: {} })
    const [modal2Open, setModal2Open] = useState(false)
    const [update, setUpdate] = useState(null)

    useEffect(() => {
        let name = 'userNotifyData'
        if (localStorage.getItem(name)) {
            let Data = JSON.parse(localStorage.getItem(name))
            Data = convert(Data)
            setData(Data)
        }
        api.get('/api/v2/get_notify')
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    Data = convert(Data)
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])


    function dealwith(item) {
        let Data = item.data
        let mark = <Tag color="warning">未读</Tag>
        if (Data.state === 1) {
            mark = <Tag color="processing">已读</Tag>
        }
        let read = <Tag data-id={item._id} color="processing" className='clogs-check' onClick={onClick}>阅读</Tag>

        return (
            <>
                <Text>{mark} {item.title}</Text>{read}
            </>
        )
    }


    function convert(item) {
        for (let i = 0; i < item.length; i++) {
            let Data = item[i].data

            if (Data.name === 'focus_on') {
                item[i].title = `${Data.uname} 关注了你`
            }
            if (Data.name === 'article_update') {
                item[i].title = `你关注的 ${Data.uname} 已经更新了文章`
            }
            if (Data.name === 'article_send') {
                item[i].title = `你关注的 ${Data.uname} 已经发布了文章`
            }
            if (Data.name === 'add_comment') {
                item[i].title = `${Data.uname} 在 ${Data.articleTitle} 文章留下评论`
            }
            if (Data.name === 'add_reply') {
                item[i].title = `${Data.uname} 在 ${Data.articleTitle} 文章回复了你`
            }
            if (Data.name === 'buy_article') {
                item[i].title = `成功购买 ${Data.articleTitle} 资源`
            }
            if (Data.name === 'system_resource') {
                item[i].title = `系统转入 ${Data.articleTitle} 托管资源到你账户上`
            }
            if (Data.name === 'seller') {
                item[i].title = `有新的客户购买 ${Data.articleTitle} 资源`
            }
            if (Data.name === 'recharge') {
                item[i].title = `已充值 ${Data.money}元 到账户余额`
            }
            if (Data.name === 'system_recharge') {
                item[i].title = `系统转入 ${Data.money}元 到账户余额`
            }
            if (Data.name === 'nv_recharge') {
                item[i].title = `系统转入的会员已到账`
            }
            if (Data.name === 'buy_recharge') {
                item[i].title = `会员已到账`
            }
            if (Data.name === 'review') {
                item[i].title = `有新的审核 ${Data.articleTitle} 要处理`
            }
            if (Data.name === 'approved') {
                item[i].title = `你的 ${Data.articleTitle} 文章已通过审核`
            }
            if (Data.name === 'reject') {
                item[i].title = `你的 ${Data.articleTitle} 文章审核被拒绝了`
            }
            if (Data.name === 'article_show') {
                item[i].title = `你的 ${Data.articleTitle} 文章已恢复了`
            }
            if (Data.name === 'article_hide') {
                item[i].title = `你的 ${Data.articleTitle} 文章被隐藏了`
            }
        }
        return item
    }


    function onClick(e) {
        let id = e.target.dataset.id
        let name = 'userNotifyData'
        let Data = localStorage.getItem(name) ? convert(JSON.parse(localStorage.getItem(name))) : []
        for (let i = 0; i < Data.length; i++) {
            if (Data[i]._id === id) {
                setModalData(Data[i])
                setModal2Open(true)
                return
            }
        }
    }

    function onOk() {
        api.post('/api/v2/read_notify', {
            data: JSON.stringify({
                id: modalData._id
            }),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    props.verifyToken(props.state.token)
                    setUpdate(Date.now())
                    setModal2Open(false)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    return (
        <div>
            <List
                header={<div>站内信</div>}
                dataSource={data}
                bordered
                style={{ width: props.state.isMobile ? '98%' : '60%', margin: 'auto' }}
                renderItem={(item) => (
                    <List.Item>
                        {dealwith(item)}
                    </List.Item>
                )}
            />
            <Modal
                centered
                open={modal2Open}
                onOk={onOk}
                onCancel={() => setModal2Open(false)}
                okText='已读'
                cancelText='关闭'
            >
                <div>
                    <Title level={4} style={{ marginTop: 5, marginBottom: 5 }}>{modalData.title}</Title>
                    <Text type="secondary" style={{ fontSize: 12 }}>{modalData.ctime}</Text>
                </div>
                <div style={{ marginTop: 10 }}>
                    {
                        modalData.data.name === 'focus_on' ?
                            <Text ><Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 关注了你,继续努力,加油!</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'article_update' ?
                            <Text >你关注的作者已经更新了 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'article_send' ?
                            <Text >你关注的作者已经发布了 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'add_reply' ?
                            <Text >{modalData.data.uname} 在 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章回复了你,内容: {modalData.data.text}</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'add_comment' ?
                            <Text >{modalData.data.uname} 在 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章留下评论,内容: {modalData.data.text}</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'buy_article' ?
                            <Text >你花了 {modalData.data.money}元买 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章的资源,如果你还没收到资源,请与站务联系处理</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'system_resource' ?
                            <Text >来自系统转入的 <Link href={`${props.state.url}/aan`}>{modalData.data.articleTitle}</Link> 托管的资源,如果你还没收到资源,请与站务联系处理</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'seller' ?
                            <Text >有新的客户购买了 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章的资源,你已收到 {modalData.data.money}元,如果你的账户还没收到该收入,请与站务联系处理</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'recharge' ?
                            <Text >已充值 {modalData.data.money}元,如果你充值的金额未到账,请与站务联系处理</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'system_recharge' ?
                            <Text >系统转已入 {modalData.data.money}元 到你的账户余额上,请检查是否到账</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'nv_recharge' ?
                            <Text >系统转入的会员已到账,过期时间: {modalData.data.time}</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'buy_recharge' ?
                            <Text >会员已到账,过期时间: {modalData.data.time}</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'review' ?
                            <Text >有新的审核 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章需要处理</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'approved' ?
                            <Text >你的 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章已经通过审核</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'reject' ?
                            <Text >你的 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章审核已经被拒绝了</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'article_show' ?
                            <Text >你的 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章已恢复了</Text>
                            :
                            null
                    }
                    {
                        modalData.data.name === 'article_hide' ?
                            <Text >你的 <Link href={`/t/${modalData.data.nid}`}>{modalData.data.articleTitle}</Link> 文章被隐藏了</Text>
                            :
                            null
                    }
                </div>
                <Divider style={{ marginTop: 10 }} />
            </Modal>
        </div>
    )
}

export default Notify;